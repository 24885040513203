/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { TransPb } from "../../gen/body/trans";
import { ClientType_Enum, clientType_EnumFromJSON, clientType_EnumToJSON } from "../../gen/cloud_config/client_type";
import { VehicleFault_Enum, vehicleFault_EnumFromJSON, vehicleFault_EnumToJSON } from "../../gen/common/fault";
import { Vector3dPb } from "../../gen/eigen_lcm/Vector3d";
import {
  GoToWaypointSource_Enum,
  goToWaypointSource_EnumFromJSON,
  goToWaypointSource_EnumToJSON,
} from "../../gen/skills/go_to_waypoint_source";
import {
  UpAndOverState_Enum,
  upAndOverState_EnumFromJSON,
  upAndOverState_EnumToJSON,
} from "../../gen/skills/up_and_over_state";
import { Uuid } from "../../gen/uuid_util/uuid";
import {
  LandingPadAnchorPoseSource_Enum,
  landingPadAnchorPoseSource_EnumFromJSON,
  landingPadAnchorPoseSource_EnumToJSON,
} from "../../gen/waypoints/landing_pad_anchor_pose_source";
import { PoseIdPb } from "../nav_cam_reconstruction/pose_id";

export const protobufPackage = "skills";

/**
 * This lists existing concepts of frames in skills. Additional frames may be added over time,
 * but will require additional support in the frames API.
 */
export interface FrameType {
}

export enum FrameType_Enum {
  INVALID = 0,
  /**
   * NAV - Vehicle location when the autonomy engine starts prior to takeoff. The origin of the NAV
   * frame does not necessarily coincide with the takeoff location
   */
  NAV = 1,
  /**
   * WORLD - The world frame is a gps-aligned frame that is centered & linearized around a gps origin,
   * chosen by the state estimation stack.  The origin will be the gps origin and will be
   * East-North-Up (ENU). Note that the GPS heading will be aligned with the world frame's heading
   * at the time the frame is initialized.
   */
  WORLD = 2,
  /**
   * GPS - The GPS frame refers to the GPS coordinates, and heading is defined ENU.
   * X = latitude, positive North. Y = longitude, positive East, Z = altitude MSL
   * Coordinates are specified in the wgs84 datum.
   */
  GPS = 3,
  /**
   * GLOBAL_MAP - The global map frame is also sometimes referred to as "optimized nav" or "structure scan"
   * This uses pose refinement to have a more accurate & less drifty estimate
   */
  GLOBAL_MAP = 4,
  /**
   * NAV_TAKEOFF - A gravity aligned frame with the origin and heading set as the vehicle's orientation
   * at takeoff, when the vehicle is on the ground and the props start spinning.
   */
  NAV_TAKEOFF = 5,
  /**
   * NAV_CAM_POSE_GRAPH - One of many frames defined by the nav cam pose graph. The ncpg_frame_info field must
   * also be set in order to indicate the specific frame.
   */
  NAV_CAM_POSE_GRAPH = 6,
  /** WORLD_PILOT_RELATIVE - A Frame relative to the current user's location, aligned to the global frame */
  WORLD_PILOT_RELATIVE = 7,
  /** WORLD_TAKEOFF - Same as world frame except the origin is at the takeoff position instead of the gps origin. */
  WORLD_TAKEOFF = 8,
  /** NAV_HEIGHT_ABOVE_GROUND - This is essentially a navigation frame but with the origin at the ground plane */
  NAV_HEIGHT_ABOVE_GROUND = 9,
  /**
   * SITE - A frame used for "site missions" (i.e., those that are linked to the a "site"). `SITE`
   * waypoints **must** be used in conjunction with a nav camera pose graph (NCPG).
   */
  SITE = 10,
  /**
   * GPS_ABOVE_GROUND_LEVEL - The GPS frame refers to the GPS coordinates, and heading is defined ENU.
   * X = latitude, positive North. Y = longitude, positive East, Z = altitude AGL
   * ground level refers to the terrain height, and can be queried from DTED data.
   */
  GPS_ABOVE_GROUND_LEVEL = 11,
  UNRECOGNIZED = -1,
}

export function frameType_EnumFromJSON(object: any): FrameType_Enum {
  switch (object) {
    case 0:
    case "INVALID":
      return FrameType_Enum.INVALID;
    case 1:
    case "NAV":
      return FrameType_Enum.NAV;
    case 2:
    case "WORLD":
      return FrameType_Enum.WORLD;
    case 3:
    case "GPS":
      return FrameType_Enum.GPS;
    case 4:
    case "GLOBAL_MAP":
      return FrameType_Enum.GLOBAL_MAP;
    case 5:
    case "NAV_TAKEOFF":
      return FrameType_Enum.NAV_TAKEOFF;
    case 6:
    case "NAV_CAM_POSE_GRAPH":
      return FrameType_Enum.NAV_CAM_POSE_GRAPH;
    case 7:
    case "WORLD_PILOT_RELATIVE":
      return FrameType_Enum.WORLD_PILOT_RELATIVE;
    case 8:
    case "WORLD_TAKEOFF":
      return FrameType_Enum.WORLD_TAKEOFF;
    case 9:
    case "NAV_HEIGHT_ABOVE_GROUND":
      return FrameType_Enum.NAV_HEIGHT_ABOVE_GROUND;
    case 10:
    case "SITE":
      return FrameType_Enum.SITE;
    case 11:
    case "GPS_ABOVE_GROUND_LEVEL":
      return FrameType_Enum.GPS_ABOVE_GROUND_LEVEL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FrameType_Enum.UNRECOGNIZED;
  }
}

export function frameType_EnumToJSON(object: FrameType_Enum): string {
  switch (object) {
    case FrameType_Enum.INVALID:
      return "INVALID";
    case FrameType_Enum.NAV:
      return "NAV";
    case FrameType_Enum.WORLD:
      return "WORLD";
    case FrameType_Enum.GPS:
      return "GPS";
    case FrameType_Enum.GLOBAL_MAP:
      return "GLOBAL_MAP";
    case FrameType_Enum.NAV_TAKEOFF:
      return "NAV_TAKEOFF";
    case FrameType_Enum.NAV_CAM_POSE_GRAPH:
      return "NAV_CAM_POSE_GRAPH";
    case FrameType_Enum.WORLD_PILOT_RELATIVE:
      return "WORLD_PILOT_RELATIVE";
    case FrameType_Enum.WORLD_TAKEOFF:
      return "WORLD_TAKEOFF";
    case FrameType_Enum.NAV_HEIGHT_ABOVE_GROUND:
      return "NAV_HEIGHT_ABOVE_GROUND";
    case FrameType_Enum.SITE:
      return "SITE";
    case FrameType_Enum.GPS_ABOVE_GROUND_LEVEL:
      return "GPS_ABOVE_GROUND_LEVEL";
    case FrameType_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Note(max): I am adding a "FrameDetails" message instead of replacing the existing frame enum
 * because we can't deprecate/migrate fields for types contained within the mission pb. This is
 * because the mission pb type is used in the cloud and also on vehicles with different flashpack
 * versions.
 */
export interface FrameDetails {
  /**
   * Only valid when frame_type==NAV_CAM_POSE_GRAPH, the pose_id refers to a gravity-aligned frame
   * centered on the vehicle pose at the given utime from the given flight.
   */
  poseId?:
    | PoseIdPb
    | undefined;
  /**
   * An estimation of the absolute altitude of the frame. Used to recover the original measured
   * absolute altitude. altitude for a waypoint, which may be used instead of the frame_z_waypoint
   * value during frame conversion.
   * - Directly measured GPS altitude: wgs84_z_waypoint
   * - frame_z_waypoint can be indirectly measured (e.g., taking a vehicle pose and converting it
   *   to another frame)
   * - wgs84_z_frame is set as wgs84_z_waypoint - frame_z_waypoint
   * - Recovering original wgs84_z_waypoint: wgs84_z_frame + frame_z_waypoint
   *
   * wgs84_z_frame must be updated during frame conversion, but should be left alone if
   * modifying the z value outside of the context of frame conversion. Cleared when consumed
   * (i.e., when converting from the WORLD frame to some other frame).
   */
  frameAltitudeInfo?: FrameDetails_FrameAltitudeInfo | undefined;
}

export interface FrameDetails_FrameAltitudeInfo {
  /**
   * NOTE(duncan): Could add quality metric here to inform the frame converter on whether to use
   * this value; for now, we decided that we can only set the value if it is high enough quality
   * to use.
   */
  wgs84ZFrame: number;
}

export interface Frame {
  frameType: FrameType_Enum;
  frameDetails: FrameDetails | undefined;
}

/** XY position coordinates. */
export interface PositionXY {
  /** X coordinate of the position */
  x: number;
  /** Y coordinate of the position */
  y: number;
  /** Frame of reference for XY position */
  frame: FrameType_Enum;
  frameDetails: FrameDetails | undefined;
}

/** Z position coordinates. */
export interface PositionZ {
  /** Z coordinate of the position. */
  value: number;
  /** Frame of reference for Z position. */
  frame: FrameType_Enum;
  frameDetails: FrameDetails | undefined;
}

/** XYZ position coordinates with the ability to have seperate frames for XY and Z. */
export interface PositionXYZ {
  xy: PositionXY | undefined;
  z: PositionZ | undefined;
}

/** Desired heading value and frame of reference. */
export interface Heading {
  /** Heading value in radians. 0 is east, pi/2 is north, pi is west, 3pi/2 is south. */
  value: number;
  /** Frame of reference for the heading. */
  frame: FrameType_Enum;
  frameDetails: FrameDetails | undefined;
}

/**
 * Desired camera gimbal pitch type.
 * GimbalPitch does not have a frame because it is always defined w.r.t. gravity.
 */
export interface GimbalPitch {
  /** The pitch, in radians. Positive pitch looks down. */
  value: number;
}

export interface GimbalPitchRate {
  value: number;
  aggressiveness: number;
  maxGimbalPitchRate: number;
}

export interface HeadingRate {
  value: number;
  aggressiveness: number;
  maxHeadingRate: number;
}

/**
 * only commands heading, heading rate and gimbal pitch. Heading rate is used to freelook
 * (continuous lookat)
 */
export interface LookAtCommand {
  utime: number;
  heading: Heading | undefined;
  gimbalPitch: GimbalPitch | undefined;
  gimbalPitchRate: GimbalPitchRate | undefined;
  headingRate: HeadingRate | undefined;
  source: GoToWaypointSource_Enum;
  /**
   * enforce_timeout defaults to false,
   * no timeout will be used if the field is left blank
   * https://protobuf.dev/programming-guides/proto3/#default
   */
  enforceTimeout: boolean;
}

/** Waypoint with heading and gimbal pitch */
export interface Waypoint {
  /** XY position of the waypoint */
  xy:
    | PositionXY
    | undefined;
  /** Z position of the waypoint */
  z:
    | PositionZ
    | undefined;
  /** Heading at the waypoint */
  heading:
    | Heading
    | undefined;
  /** Gimbal pitch at the waypoint */
  gimbalPitch: GimbalPitch | undefined;
}

/** A waypoint without a specified heading or gimbal pitch */
export interface PositionWaypoint {
  xy: PositionXY | undefined;
  z: PositionZ | undefined;
}

/** A waypoint with a look an associated look at point */
export interface LookAtWaypoint {
  waypoint: PositionWaypoint | undefined;
  lookAtPoint:
    | PositionWaypoint
    | undefined;
  /**
   * NOTE(Matias): The heading of the vehicle is ambiguous in cases where the `waypoint` ->
   * `look_at_point` is colinear with the direction of gravity. To disambiguate, we can specify a
   * `vertical_look_at_heading` to indicate the desired heading of the vehicle in this case. The
   * behavior of the vehicle is undefined if this is enabled when `waypoint` -> `look_at_point` is
   * not colinear with the direction of gravity.
   */
  useVerticalLookAtHeading: boolean;
  verticalLookAtHeading: Heading | undefined;
}

export interface DockWaypointEstimate {
  source: LandingPadAnchorPoseSource_Enum;
  dockWaypoint:
    | Waypoint
    | undefined;
  /** meters */
  uncertainty: number;
  /** The `dock_waypoint` field, converted to the nav frame, for easy debugging. */
  navTWaypoint:
    | TransPb
    | undefined;
  /** The `dock_waypoint` field, converted to GPS, for easy visualization on a map. */
  dockWaypointGps: Waypoint | undefined;
}

export interface DockWaypointList {
  utime: number;
  dockWaypointEstimates: DockWaypointEstimate[];
  selectedSource: LandingPadAnchorPoseSource_Enum;
}

export interface DistAndTimeToTargetWaypoint {
  /** Either straight-line or backtrack distance */
  distance: number;
  /** Uses motion arg speed, in seconds */
  time: number;
  /** Motion arg for background task */
  motionArgs:
    | MotionArgs
    | undefined;
  /** Flight energy required to reach waypoint, in J */
  flightEnergyRequired: number;
  /** Highest point reached on path, in meters */
  additionalAscent: number;
}

export interface TargetWaypointData {
  /** Time at which the waypoint location was recorded or created */
  waypoint: Waypoint | undefined;
  utimeWhenRecorded: number;
  /** Note: distances will be set to -1 if distance is unable to be calculated */
  euclideanDistance3d: number;
  euclideanDistance2d: number;
  /**
   * Distance and time estimates to return to this waypoint (captures up-and-over or backtrack
   * return distances, depending on the return settings)
   */
  returnDistAndTime:
    | DistAndTimeToTargetWaypoint
    | undefined;
  /** Whether we can convert this waypoint to the NAV frame and use it to return */
  isReachable: boolean;
  waypointInGps: Waypoint | undefined;
}

export interface Pilot {
  /** corresponds to a key in reserved_waypoint_keys_t */
  waypointKey: number;
  clientType: ClientType_Enum;
}

/**
 * Waypoints list to publish from the waypoints front, which maintains special waypoints
 * even if the skills front restarts.
 */
export interface WaypointsList {
  utime: number;
  waypointData: { [key: number]: TargetWaypointData };
  pilot:
    | Pilot
    | undefined;
  /**
   * Specifies the waypoint keys used for various RTXs (e.g. low battery, lost connection, etc.)
   * Key corresponds to CriticalRTXType enum, value corresponds to a key in reserved_waypoint_keys_t
   */
  criticalRtxDestinations: { [key: number]: number };
  loadedFromFile: boolean;
  /** Current heading used for heading returns (atti mode or no return destinations available) */
  backupLostCommsReturnHeadingNav: number;
  flightId: string;
  /** This will match the latest set/clear request nonce. */
  nonce: number;
  batteryRange: number;
  batteryRangeIsValid: boolean;
}

export interface WaypointsList_WaypointDataEntry {
  key: number;
  value: TargetWaypointData | undefined;
}

export interface WaypointsList_CriticalRtxDestinationsEntry {
  key: number;
  value: number;
}

export interface DistanceDataForWireless {
  utime: number;
  controllerData: DistanceDataForWireless_Data | undefined;
  externalRadioData: DistanceDataForWireless_Data | undefined;
  dockData: DistanceDataForWireless_Data | undefined;
}

export interface DistanceDataForWireless_Data {
  distance: number;
  /** True if the takeoff point is being used in place of the waypoint */
  usingTakeoffAsBackup: boolean;
  /** True if neither the waypoint nor takeoff exists, or we cannot compute the distance to them */
  stale: boolean;
}

/**
 * WaypointsRequest is sent from the skills api to the waypoints front, to request saving
 * specific waypoints (e.g. if a user or skill sets the home point) and to request deleting
 * specific waypoints (e.g. if a user or skill deletes the home point).
 */
export interface WaypointsRequest {
  utime: number;
  /** The key int64 is expected to be one of the ints in reserved_waypoint_keys_t */
  waypoints: { [key: number]: Waypoint };
  keysToClear: number[];
  nonce: number;
}

export interface WaypointsRequest_WaypointsEntry {
  key: number;
  value: Waypoint | undefined;
}

/**
 * AnchorWaypoints is sent from waypoints front to the planner, to specify relevant waypoints
 * that the planner uses to update the anchor height manager (relative height points).
 */
export interface AnchorWaypoints {
  utime: number;
  launch: Waypoint | undefined;
}

/** Landmark is a waypoint with a label */
export interface Landmark {
  label: string;
  waypoint: Waypoint | undefined;
}

/** List of landmarks, used to display landmarks in AR */
export interface LandmarkList {
  utime: number;
  landmarks: Landmark[];
}

/**
 * ------------------------------------------------------------------------
 * Everything beyond this point specifies how to fly to a given waypoint
 * ------------------------------------------------------------------------
 * Enum to define the Height (z) behavior of the drone during the traversal task
 */
export interface HeightMode {
}

export enum HeightMode_Enum {
  /** UNSPECIFIED - Invalid. */
  UNSPECIFIED = 0,
  /** GRADUAL - Take a straight line path from the starting point to the target point. */
  GRADUAL = 1,
  /**
   * CONSTANT_BEGINNING - Maintain vehicle height of starting position until target waypoint is reached in X & Y;
   * then ascend or descend to reach the target waypoint Z.
   */
  CONSTANT_BEGINNING = 2,
  /**
   * CONSTANT_END - Ascend or descend to reach the target waypoint Z, then maintain that height until
   * reaching the target waypoint.
   */
  CONSTANT_END = 3,
  /**
   * MAX - Traversal height will be maximum of {starting Z, target waypoint Z, specified height}
   * Ascend or descend to reach this height, traverse at that height until reaching the target
   * waypoint in X & Y, then descend or ascend to target waypoint.
   * If no max_args are given, traversal height will be
   * maximum of {starting Z, target waypoint Z}
   */
  MAX = 4,
  /** CUSTOM - Traversal height will be the specified custom height. This requires custom_height_args. */
  CUSTOM = 5,
  /**
   * RAMP - Ramp to and from a certain height at specified elevation angles at the beginning and end of
   * the traversal task.
   */
  RAMP = 6,
  UNRECOGNIZED = -1,
}

export function heightMode_EnumFromJSON(object: any): HeightMode_Enum {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return HeightMode_Enum.UNSPECIFIED;
    case 1:
    case "GRADUAL":
      return HeightMode_Enum.GRADUAL;
    case 2:
    case "CONSTANT_BEGINNING":
      return HeightMode_Enum.CONSTANT_BEGINNING;
    case 3:
    case "CONSTANT_END":
      return HeightMode_Enum.CONSTANT_END;
    case 4:
    case "MAX":
      return HeightMode_Enum.MAX;
    case 5:
    case "CUSTOM":
      return HeightMode_Enum.CUSTOM;
    case 6:
    case "RAMP":
      return HeightMode_Enum.RAMP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HeightMode_Enum.UNRECOGNIZED;
  }
}

export function heightMode_EnumToJSON(object: HeightMode_Enum): string {
  switch (object) {
    case HeightMode_Enum.UNSPECIFIED:
      return "UNSPECIFIED";
    case HeightMode_Enum.GRADUAL:
      return "GRADUAL";
    case HeightMode_Enum.CONSTANT_BEGINNING:
      return "CONSTANT_BEGINNING";
    case HeightMode_Enum.CONSTANT_END:
      return "CONSTANT_END";
    case HeightMode_Enum.MAX:
      return "MAX";
    case HeightMode_Enum.CUSTOM:
      return "CUSTOM";
    case HeightMode_Enum.RAMP:
      return "RAMP";
    case HeightMode_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface RampArgs {
  /**
   * angle from the horizontal that defines the elevation of the start ramp.
   * pi/2 implies going straight up/down to the traverse_height and 0 is no ramp.
   */
  initialElevationAngle: number;
  /** Height at which point the initial ramp will be terminated if it reaches it. */
  traverseHeight:
    | PositionZ
    | undefined;
  /**
   * angle from the horizontal that defines the elevation of the end ramp.
   * pi/2 implies going straight up/down and 0 is no ramp.
   * If `ignore_waypoint_z = True` this will be ignored.
   */
  finalElevationAngle: number;
}

/** Arguments specifying how the drone should move to a waypoint in (x, y, z) */
export interface TraversalMotionArgs {
  /**
   * Target speed for ascension, if the height_mode requires ascension
   * If speed is 0 (within some tolerance), default will be min(max_ascend_speed, speed)
   */
  ascendSpeed: number;
  /**
   * Target speed for traversal.
   * If speed is 0 (within some tolerance), a default (e.g. 5 m/s) will be used.
   */
  speed: number;
  /**
   * Target speed for descension, if the height_mode requires descension
   * If speed is 0 (within some tolerance), default will be min(max_descend_speed, speed)
   */
  descendSpeed: number;
  /**
   * If true, the drone will assume the target destination matches the starting position's height,
   * regardless of the target waypoint's specified Z value.
   */
  ignoreWaypointZ: boolean;
  /** Defines one of the possible height modes */
  heightMode: HeightMode_Enum;
  maxArgs?: PositionZ | undefined;
  customArgs?: PositionZ | undefined;
  rampArgs?:
    | RampArgs
    | undefined;
  /**
   * If false, the drone will follow a straight line path to the target waypoint and if there are
   * big enough obstacles in the way, the traversal task will fail.
   * If true, we will do local A* planning around the voxel map to find a path around obstacles.
   * Requires obstacle avoidance to be enabled to function.
   */
  usePathfinder: boolean;
  /**
   * If true, we will use building and/or terrain data to plan an obstacle free path to the target.
   * Use in conjunction with use_pathfinder, if you want the drone to also locally plan around
   * obstacles that we detect that may not be in the building / terrain data.
   */
  useGlobalPathfinder: boolean;
}

/** Enum to define heading behavior during waypoint task */
export interface HeadingMode {
}

export enum HeadingMode_Enum {
  /** UNSPECIFIED - Invalid */
  UNSPECIFIED = 0,
  /**
   * GRADUAL - Interpolate between the starting heading & the target waypoint heading,
   * arriving at target waypoint heading when target waypoint position is reached.
   * If ignore_target_heading is True, this will match CONSTANT_BEGINNING.
   */
  GRADUAL = 1,
  /**
   * FORWARD - Face along the vector from the start waypoint to the target waypoint. This will give a fixed
   * heading for the duration of traversal.
   */
  FORWARD = 2,
  /**
   * BACKWARD - Face away from the vector from the start waypoint to the target waypoint. This will give a
   * fixed heading for the duration of traversal.
   */
  BACKWARD = 3,
  /** CONSTANT_BEGINNING - Match the starting heading */
  CONSTANT_BEGINNING = 4,
  /**
   * CONSTANT_END - Match the heading of the target waypoint for the duration of traversal.
   * If ignore_target_heading is True, this will match CONSTANT_BEGINNING.
   */
  CONSTANT_END = 5,
  /** CUSTOM - Custom heading between waypoints */
  CUSTOM = 6,
  /** LOOKAHEAD - Lookahead to a near future point along the traversal path */
  LOOKAHEAD = 7,
  /** LOOKAT_POINT - Apply heading to keep an arbitrary look at point in view */
  LOOKAT_POINT = 8,
  /**
   * LOOKAT_TARGET - Apply heading to keep target waypoint in view wrt heading (pitch is independent). This is
   * different than FORWARD as it will make any adjustments needed to keep the target in view.
   */
  LOOKAT_TARGET = 9,
  UNRECOGNIZED = -1,
}

export function headingMode_EnumFromJSON(object: any): HeadingMode_Enum {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return HeadingMode_Enum.UNSPECIFIED;
    case 1:
    case "GRADUAL":
      return HeadingMode_Enum.GRADUAL;
    case 2:
    case "FORWARD":
      return HeadingMode_Enum.FORWARD;
    case 3:
    case "BACKWARD":
      return HeadingMode_Enum.BACKWARD;
    case 4:
    case "CONSTANT_BEGINNING":
      return HeadingMode_Enum.CONSTANT_BEGINNING;
    case 5:
    case "CONSTANT_END":
      return HeadingMode_Enum.CONSTANT_END;
    case 6:
    case "CUSTOM":
      return HeadingMode_Enum.CUSTOM;
    case 7:
    case "LOOKAHEAD":
      return HeadingMode_Enum.LOOKAHEAD;
    case 8:
    case "LOOKAT_POINT":
      return HeadingMode_Enum.LOOKAT_POINT;
    case 9:
    case "LOOKAT_TARGET":
      return HeadingMode_Enum.LOOKAT_TARGET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HeadingMode_Enum.UNRECOGNIZED;
  }
}

export function headingMode_EnumToJSON(object: HeadingMode_Enum): string {
  switch (object) {
    case HeadingMode_Enum.UNSPECIFIED:
      return "UNSPECIFIED";
    case HeadingMode_Enum.GRADUAL:
      return "GRADUAL";
    case HeadingMode_Enum.FORWARD:
      return "FORWARD";
    case HeadingMode_Enum.BACKWARD:
      return "BACKWARD";
    case HeadingMode_Enum.CONSTANT_BEGINNING:
      return "CONSTANT_BEGINNING";
    case HeadingMode_Enum.CONSTANT_END:
      return "CONSTANT_END";
    case HeadingMode_Enum.CUSTOM:
      return "CUSTOM";
    case HeadingMode_Enum.LOOKAHEAD:
      return "LOOKAHEAD";
    case HeadingMode_Enum.LOOKAT_POINT:
      return "LOOKAT_POINT";
    case HeadingMode_Enum.LOOKAT_TARGET:
      return "LOOKAT_TARGET";
    case HeadingMode_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Enum to define gimbal pitch behavior during waypoint task */
export interface GimbalPitchMode {
}

export enum GimbalPitchMode_Enum {
  /** UNSPECIFIED - Invalid */
  UNSPECIFIED = 0,
  /**
   * GRADUAL - Interpolate bewteen starting gimbal pitch & target waypoint gimbal pitch,
   * arriving at target waypoint gimbal pitch when target waypoint position is reached.
   * If ignore_target_gimbal_pitch is True, this will match CONSTANT_BEGINNING.
   */
  GRADUAL = 1,
  /** CONSTANT_BEGINNING - Match starting gimbal pitch throughout waypoint task. */
  CONSTANT_BEGINNING = 2,
  /**
   * CONSTANT_END - Match target waypoint gimbal pitch throughout waypoint task.
   * If ignore_target_gimbal_pitch is True, this will match CONSTANT_BEGINNING.
   */
  CONSTANT_END = 3,
  /** CUSTOM - Custom gimbal pitch between waypoints */
  CUSTOM = 4,
  /** LOOKAHEAD - Lookahead to a near future point along the traversal path */
  LOOKAHEAD = 5,
  /** LOOKAT_TARGET - Apply gimbal pitch to keep target waypoint in view wrt pitch (heading is independent) */
  LOOKAT_TARGET = 6,
  /** LOOKAT_POINT - Apply gimbal pitch to keep an arbitrary height in view */
  LOOKAT_POINT = 7,
  UNRECOGNIZED = -1,
}

export function gimbalPitchMode_EnumFromJSON(object: any): GimbalPitchMode_Enum {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return GimbalPitchMode_Enum.UNSPECIFIED;
    case 1:
    case "GRADUAL":
      return GimbalPitchMode_Enum.GRADUAL;
    case 2:
    case "CONSTANT_BEGINNING":
      return GimbalPitchMode_Enum.CONSTANT_BEGINNING;
    case 3:
    case "CONSTANT_END":
      return GimbalPitchMode_Enum.CONSTANT_END;
    case 4:
    case "CUSTOM":
      return GimbalPitchMode_Enum.CUSTOM;
    case 5:
    case "LOOKAHEAD":
      return GimbalPitchMode_Enum.LOOKAHEAD;
    case 6:
    case "LOOKAT_TARGET":
      return GimbalPitchMode_Enum.LOOKAT_TARGET;
    case 7:
    case "LOOKAT_POINT":
      return GimbalPitchMode_Enum.LOOKAT_POINT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return GimbalPitchMode_Enum.UNRECOGNIZED;
  }
}

export function gimbalPitchMode_EnumToJSON(object: GimbalPitchMode_Enum): string {
  switch (object) {
    case GimbalPitchMode_Enum.UNSPECIFIED:
      return "UNSPECIFIED";
    case GimbalPitchMode_Enum.GRADUAL:
      return "GRADUAL";
    case GimbalPitchMode_Enum.CONSTANT_BEGINNING:
      return "CONSTANT_BEGINNING";
    case GimbalPitchMode_Enum.CONSTANT_END:
      return "CONSTANT_END";
    case GimbalPitchMode_Enum.CUSTOM:
      return "CUSTOM";
    case GimbalPitchMode_Enum.LOOKAHEAD:
      return "LOOKAHEAD";
    case GimbalPitchMode_Enum.LOOKAT_TARGET:
      return "LOOKAT_TARGET";
    case GimbalPitchMode_Enum.LOOKAT_POINT:
      return "LOOKAT_POINT";
    case GimbalPitchMode_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Arguments that describe what the drone's camera angle (drone heading and gimbal pitch) should
 * be while moving to a waypoint.
 */
export interface LookAtMotionArgs {
  /** If true, the drone will not yaw upon reaching target waypoint. */
  ignoreTargetHeading: boolean;
  /** Define heading behavior throughout waypoint task. */
  headingMode: HeadingMode_Enum;
  /** If CUSTOM heading mode, use this heading value */
  customHeading:
    | Heading
    | undefined;
  /** If true, the gimbal pitch will not change while moving to the waypoint. */
  ignoreTargetGimbalPitch: boolean;
  /** Define gimbal pitch behavior throughout waypoint task. */
  gimbalPitchMode: GimbalPitchMode_Enum;
  /** If CUSTOM gimbal pitch mode, use this gimbal pitch value */
  customGimbalPitch:
    | GimbalPitch
    | undefined;
  /** An arbitrary point to look at. Applies if the heading_mode is LOOKAT_POINT */
  lookatPoint:
    | PositionXY
    | undefined;
  /** An arbitrary height to look at. Applies if the gimbal_pitch_mode is LOOKAT_POINT */
  lookatHeight:
    | PositionZ
    | undefined;
  /**
   * An offset translation to apply to the target point when the heading / gimbal pitch mode results
   * in looking at the target waypoint. This is useful when the target waypoint is not the object of
   * interest, like in dock returns where we want to look at the dock but the target waypoint we
   * return to is above the dock.
   */
  targetTLookat: Vector3dPb | undefined;
}

export interface FreeLookMode {
}

export enum FreeLookMode_Enum {
  UNKNOWN = 0,
  /** PAN_AND_TILT - heading and gimbal pitch controlled by movement commands */
  PAN_AND_TILT = 1,
  /** PAN_ONLY - only heading controlled by movement commands */
  PAN_ONLY = 2,
  /** TILT_ONLY - only pitch controlled by movement commands */
  TILT_ONLY = 3,
  /** DISABLED - movement commands do not change the camera orientation */
  DISABLED = 4,
  UNRECOGNIZED = -1,
}

export function freeLookMode_EnumFromJSON(object: any): FreeLookMode_Enum {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return FreeLookMode_Enum.UNKNOWN;
    case 1:
    case "PAN_AND_TILT":
      return FreeLookMode_Enum.PAN_AND_TILT;
    case 2:
    case "PAN_ONLY":
      return FreeLookMode_Enum.PAN_ONLY;
    case 3:
    case "TILT_ONLY":
      return FreeLookMode_Enum.TILT_ONLY;
    case 4:
    case "DISABLED":
      return FreeLookMode_Enum.DISABLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FreeLookMode_Enum.UNRECOGNIZED;
  }
}

export function freeLookMode_EnumToJSON(object: FreeLookMode_Enum): string {
  switch (object) {
    case FreeLookMode_Enum.UNKNOWN:
      return "UNKNOWN";
    case FreeLookMode_Enum.PAN_AND_TILT:
      return "PAN_AND_TILT";
    case FreeLookMode_Enum.PAN_ONLY:
      return "PAN_ONLY";
    case FreeLookMode_Enum.TILT_ONLY:
      return "TILT_ONLY";
    case FreeLookMode_Enum.DISABLED:
      return "DISABLED";
    case FreeLookMode_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AllowedEdges {
  vehiclePaths: boolean;
  loadedPaths: boolean;
}

/** Arguments related to graph traversal */
export interface GraphMotionArgs {
  speed: number;
  /**
   * Maybe:
   * Choice of Algorithm for connecting with local geometry (A*)
   * Sampling method (build PRM incrementally)
   */
  allowedEdges:
    | AllowedEdges
    | undefined;
  /**
   * If true, the drone will follow a graph path based spline to the target waypoint.
   * Otherwise, will use timed waypoint trajectory
   */
  useSpline: boolean;
  /**
   * Scales down vehicle speed as it moves near the target waypoint.
   * NOTE(shreetej): For now, the parameters associated with slowing down near the target
   * are non-configurable and reasonable for most use cases. This is to keep things simple,
   * though if we see a strong need to allow configurability in the future, we should add that
   * functionality.
   */
  slowdownNearTarget: boolean;
}

/** Arguments to specify how the drone should move and rotate while flying to a waypoint. */
export interface MotionArgs {
  /** Arguments for the movement speed and height. */
  traversalArgs:
    | TraversalMotionArgs
    | undefined;
  /** Arguments for the camera's angle while moving. */
  lookAtArgs:
    | LookAtMotionArgs
    | undefined;
  /** Arguments for how the drone should calculate it's path through the graph. */
  graphArgs:
    | GraphMotionArgs
    | undefined;
  /** Threshold arguments for waypoint termination. */
  terminationArgs: WaypointTerminationArgs | undefined;
}

/** Threshold arguments for waypoint termination. */
export interface WaypointTerminationArgs {
  /** XY distance threshold for waypoint achievement. */
  xyPositionThreshold: number;
  /** Z distance threshold for waypoint achievement. */
  zPositionThreshold: number;
}

export interface WaypointKey {
  value: number;
}

export interface TargetWaypoint {
  key?: WaypointKey | undefined;
  waypoint?: Waypoint | undefined;
}

export interface GoToWaypointRequest {
  utime: number;
  /** Nonce that matches utime when the request first hits Skybus */
  nonce: number;
  /** If false, stop flying to current waypoint. */
  startFlying: boolean;
  target: TargetWaypoint | undefined;
  motionArgs: MotionArgs | undefined;
  source: GoToWaypointSource_Enum;
  /** If true, the vehicle will go into ReturnAndLandAtDock policy when request is received */
  landOnComplete: boolean;
  /** Waypoint priority (0 = lowest priority) */
  priority: number;
}

export interface GoToWaypointState {
}

export enum GoToWaypointState_Enum {
  UNSPECIFIED = 0,
  INACTIVE = 1,
  PENDING = 2,
  IN_PROGRESS = 3,
  /** CAMERA_ANGLE_ADJUSTMENT - deprecated */
  CAMERA_ANGLE_ADJUSTMENT = 4,
  FINAL_ADJUSTMENT = 7,
  DONE = 5,
  FAILED = 6,
  UNRECOGNIZED = -1,
}

export function goToWaypointState_EnumFromJSON(object: any): GoToWaypointState_Enum {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return GoToWaypointState_Enum.UNSPECIFIED;
    case 1:
    case "INACTIVE":
      return GoToWaypointState_Enum.INACTIVE;
    case 2:
    case "PENDING":
      return GoToWaypointState_Enum.PENDING;
    case 3:
    case "IN_PROGRESS":
      return GoToWaypointState_Enum.IN_PROGRESS;
    case 4:
    case "CAMERA_ANGLE_ADJUSTMENT":
      return GoToWaypointState_Enum.CAMERA_ANGLE_ADJUSTMENT;
    case 7:
    case "FINAL_ADJUSTMENT":
      return GoToWaypointState_Enum.FINAL_ADJUSTMENT;
    case 5:
    case "DONE":
      return GoToWaypointState_Enum.DONE;
    case 6:
    case "FAILED":
      return GoToWaypointState_Enum.FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return GoToWaypointState_Enum.UNRECOGNIZED;
  }
}

export function goToWaypointState_EnumToJSON(object: GoToWaypointState_Enum): string {
  switch (object) {
    case GoToWaypointState_Enum.UNSPECIFIED:
      return "UNSPECIFIED";
    case GoToWaypointState_Enum.INACTIVE:
      return "INACTIVE";
    case GoToWaypointState_Enum.PENDING:
      return "PENDING";
    case GoToWaypointState_Enum.IN_PROGRESS:
      return "IN_PROGRESS";
    case GoToWaypointState_Enum.CAMERA_ANGLE_ADJUSTMENT:
      return "CAMERA_ANGLE_ADJUSTMENT";
    case GoToWaypointState_Enum.FINAL_ADJUSTMENT:
      return "FINAL_ADJUSTMENT";
    case GoToWaypointState_Enum.DONE:
      return "DONE";
    case GoToWaypointState_Enum.FAILED:
      return "FAILED";
    case GoToWaypointState_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DistanceSegments {
  upDistance: number;
  traverseDistance: number;
  downDistance: number;
}

export interface DisplacementSegments {
  upDisplacement: Vector3dPb | undefined;
  traverseDisplacements: Vector3dPb[];
  downDisplacement: Vector3dPb | undefined;
}

export interface SegmentEstimates {
  traversalSegments: SegmentEstimate[];
}

export interface SegmentEstimate {
  distance: number;
  time: number;
  groundSpeed: number;
  airSpeed: number;
  energy: number;
}

/** For traversal tasks only, indicates whether we have up, traverse, and down goals for up-and-over */
export interface HasIntermediateGoals {
  hasUpGoal: boolean;
  hasTraverseGoal: boolean;
  hasDownGoal: boolean;
}

export interface UpAndOverProgressUpperBounds {
  /** 0 to up_progress */
  upProgress: number;
  /** If no down goal, this should be 1.0 */
  traverseProgress: number;
  /** This should be 1.0 */
  downProgress: number;
}

export interface TraversalStatus {
  upAndOverState: UpAndOverState_Enum;
  hasIntermediateGoals: HasIntermediateGoals | undefined;
  upAndOverProgressUpperBounds: UpAndOverProgressUpperBounds | undefined;
  distanceSegments: DistanceSegments | undefined;
}

/** TODO(maggie): add to this */
export interface GraphStatus {
}

export interface GoToWaypointStatus {
  utime: number;
  /** This will match the latest request nonce. */
  nonce: number;
  /** This will match the latest accepted request with a target */
  acceptedTargetNonce: number;
  /** Check progress (in meters) */
  distanceTraveled: number;
  totalDistance: number;
  target: TargetWaypoint | undefined;
  state: GoToWaypointState_Enum;
  source: GoToWaypointSource_Enum;
  /** Specifies the fault that was responsible for starting a waypoint */
  triggeringFault: VehicleFault_Enum;
  /** Specifies the fault that was responsible for failing a waypoint */
  failingFault: VehicleFault_Enum;
  motionArgs: MotionArgs | undefined;
  traversalStatus?: TraversalStatus | undefined;
  graphStatus?:
    | GraphStatus
    | undefined;
  /** The target nav waypoint after all adjustments made by waypoints front. */
  adjustedTargetWaypointInNav:
    | Waypoint
    | undefined;
  /** adjusted_target_waypoint_in_nav converted to GPS frame */
  targetInGps:
    | Waypoint
    | undefined;
  /**
   * If true, and the vehicle is going to the dock, the planner will go into ReturnAndLandAtDock
   * policy when request is received
   */
  landOnComplete: boolean;
  /** Type of critical RTX that is ongoing (e.g. low battery or lost connection), if any */
  criticalRtxType: CriticalRTXType_Enum;
  /** Intermediate waypoints the vehicle will travel through to reach the target waypoint */
  intermediateWaypoints: Waypoint[];
  /** If false, the vehicle is still calculating the intermediate waypoints */
  intermediateWaypointsDoneComputing: boolean;
  /** Time left for task completion */
  timeRemaining: number;
  /** Convenience field amalgamating the states that indicate a waypoint is in progress */
  isFlyingToWaypoint: boolean;
  /** Waypoint task is done (including if it failed) - this will not be true if the task is canceled */
  doneFlyingToWaypoint: boolean;
  readyForWaypointCommands: boolean;
  /**
   * If true, vehicle is traveling to one of our stored waypoints (equivalent to
   * is_flying_to_waypoint && target.has_key)
   */
  isReturning: boolean;
  /** Unique waypoint task UUID. Used to query analytics for the same waypoint task */
  taskUuid: Uuid | undefined;
}

export interface IntermediateGoals {
  upGoal: Waypoint | undefined;
  traverseGoal: Waypoint | undefined;
  downGoal: Waypoint | undefined;
}

export interface TraversalStatusInternal {
  /**
   * If pathfinder is off (using splines), this in the frame that gets passed into splines,
   * since splines can track in multiple frames.
   * If pathfinder is on (using A*), this should be in nav frame.
   */
  intermediateGoals: IntermediateGoals | undefined;
}

/** TODO(maggie / whoever) */
export interface GraphStatusInternal {
}

/**
 * Heading values that are relevant for waypoints
 * Just like all heading values, these are in radians
 */
export interface WaypointHeadingsInternal {
  startHeadingInNav: number;
  targetHeadingInNav: number;
  currentHeadingInNav: number;
  /**
   * This is the angle formed by the current vehicle heading
   * and the line between the start and target xy points
   * This has range of [-pi, pi] (uses atan2)
   */
  relativeHeading: number;
}

/**
 * These are values that could be derived from existing messages
 * but are extracted here for ease of debugging and use in planner regression tests
 */
export interface GoToWaypointStatusInternal {
  utime: number;
  /** Start and target in nav frame */
  startInNav: TransPb | undefined;
  targetInNav: TransPb | undefined;
  waypointHeadingsInternal: WaypointHeadingsInternal | undefined;
  traversalStatusInternal?: TraversalStatusInternal | undefined;
  graphStatusInternal?: GraphStatusInternal | undefined;
}

export interface SkillsRTXSettings {
  utime: number;
  /** Ignore lost phone connection to avoid RTL */
  ignoreLostPhoneConnection: boolean;
  /** TODO(Ayush): add translators */
  lowBatteryRtxBehavior: SkillsRTXSettings_LowBatteryRtxBehavior;
}

export enum SkillsRTXSettings_LowBatteryRtxBehavior {
  INVALID = 0,
  /**
   * IGNORE - Ignore processing low battery returns. Use with EXTREME CAUTION as flight core will not
   * return on low battery anymore. If you use this option, you should react to the
   * BATTERY_LOW_RETURN fault and do your own return.
   */
  IGNORE = 1,
  /** ALWAYS_WITH_COMMS - Force low battery auto RTX regardless of vehicle settings */
  ALWAYS_WITH_COMMS = 2,
  UNRECOGNIZED = -1,
}

export function skillsRTXSettings_LowBatteryRtxBehaviorFromJSON(object: any): SkillsRTXSettings_LowBatteryRtxBehavior {
  switch (object) {
    case 0:
    case "INVALID":
      return SkillsRTXSettings_LowBatteryRtxBehavior.INVALID;
    case 1:
    case "IGNORE":
      return SkillsRTXSettings_LowBatteryRtxBehavior.IGNORE;
    case 2:
    case "ALWAYS_WITH_COMMS":
      return SkillsRTXSettings_LowBatteryRtxBehavior.ALWAYS_WITH_COMMS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SkillsRTXSettings_LowBatteryRtxBehavior.UNRECOGNIZED;
  }
}

export function skillsRTXSettings_LowBatteryRtxBehaviorToJSON(object: SkillsRTXSettings_LowBatteryRtxBehavior): string {
  switch (object) {
    case SkillsRTXSettings_LowBatteryRtxBehavior.INVALID:
      return "INVALID";
    case SkillsRTXSettings_LowBatteryRtxBehavior.IGNORE:
      return "IGNORE";
    case SkillsRTXSettings_LowBatteryRtxBehavior.ALWAYS_WITH_COMMS:
      return "ALWAYS_WITH_COMMS";
    case SkillsRTXSettings_LowBatteryRtxBehavior.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface CriticalRTXType {
}

export enum CriticalRTXType_Enum {
  /** NONE - No critical RTX ongoing */
  NONE = 0,
  LOST_CONNECTION = 1,
  LOW_BATTERY = 2,
  TOO_DARK = 3,
  SAFE_LANDING = 4,
  UNRECOGNIZED = -1,
}

export function criticalRTXType_EnumFromJSON(object: any): CriticalRTXType_Enum {
  switch (object) {
    case 0:
    case "NONE":
      return CriticalRTXType_Enum.NONE;
    case 1:
    case "LOST_CONNECTION":
      return CriticalRTXType_Enum.LOST_CONNECTION;
    case 2:
    case "LOW_BATTERY":
      return CriticalRTXType_Enum.LOW_BATTERY;
    case 3:
    case "TOO_DARK":
      return CriticalRTXType_Enum.TOO_DARK;
    case 4:
    case "SAFE_LANDING":
      return CriticalRTXType_Enum.SAFE_LANDING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CriticalRTXType_Enum.UNRECOGNIZED;
  }
}

export function criticalRTXType_EnumToJSON(object: CriticalRTXType_Enum): string {
  switch (object) {
    case CriticalRTXType_Enum.NONE:
      return "NONE";
    case CriticalRTXType_Enum.LOST_CONNECTION:
      return "LOST_CONNECTION";
    case CriticalRTXType_Enum.LOW_BATTERY:
      return "LOW_BATTERY";
    case CriticalRTXType_Enum.TOO_DARK:
      return "TOO_DARK";
    case CriticalRTXType_Enum.SAFE_LANDING:
      return "SAFE_LANDING";
    case CriticalRTXType_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseFrameType(): FrameType {
  return {};
}

export const FrameType = {
  encode(_: FrameType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FrameType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFrameType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FrameType {
    return {};
  },

  toJSON(_: FrameType): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FrameType>, I>>(base?: I): FrameType {
    return FrameType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FrameType>, I>>(_: I): FrameType {
    const message = createBaseFrameType();
    return message;
  },
};

function createBaseFrameDetails(): FrameDetails {
  return { poseId: undefined, frameAltitudeInfo: undefined };
}

export const FrameDetails = {
  encode(message: FrameDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.poseId !== undefined) {
      PoseIdPb.encode(message.poseId, writer.uint32(10).fork()).ldelim();
    }
    if (message.frameAltitudeInfo !== undefined) {
      FrameDetails_FrameAltitudeInfo.encode(message.frameAltitudeInfo, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FrameDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFrameDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.poseId = PoseIdPb.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.frameAltitudeInfo = FrameDetails_FrameAltitudeInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FrameDetails {
    return {
      poseId: isSet(object.poseId) ? PoseIdPb.fromJSON(object.poseId) : undefined,
      frameAltitudeInfo: isSet(object.frameAltitudeInfo)
        ? FrameDetails_FrameAltitudeInfo.fromJSON(object.frameAltitudeInfo)
        : undefined,
    };
  },

  toJSON(message: FrameDetails): unknown {
    const obj: any = {};
    if (message.poseId !== undefined) {
      obj.poseId = PoseIdPb.toJSON(message.poseId);
    }
    if (message.frameAltitudeInfo !== undefined) {
      obj.frameAltitudeInfo = FrameDetails_FrameAltitudeInfo.toJSON(message.frameAltitudeInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FrameDetails>, I>>(base?: I): FrameDetails {
    return FrameDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FrameDetails>, I>>(object: I): FrameDetails {
    const message = createBaseFrameDetails();
    message.poseId = (object.poseId !== undefined && object.poseId !== null)
      ? PoseIdPb.fromPartial(object.poseId)
      : undefined;
    message.frameAltitudeInfo = (object.frameAltitudeInfo !== undefined && object.frameAltitudeInfo !== null)
      ? FrameDetails_FrameAltitudeInfo.fromPartial(object.frameAltitudeInfo)
      : undefined;
    return message;
  },
};

function createBaseFrameDetails_FrameAltitudeInfo(): FrameDetails_FrameAltitudeInfo {
  return { wgs84ZFrame: 0 };
}

export const FrameDetails_FrameAltitudeInfo = {
  encode(message: FrameDetails_FrameAltitudeInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wgs84ZFrame !== 0) {
      writer.uint32(13).float(message.wgs84ZFrame);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FrameDetails_FrameAltitudeInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFrameDetails_FrameAltitudeInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.wgs84ZFrame = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FrameDetails_FrameAltitudeInfo {
    return { wgs84ZFrame: isSet(object.wgs84ZFrame) ? globalThis.Number(object.wgs84ZFrame) : 0 };
  },

  toJSON(message: FrameDetails_FrameAltitudeInfo): unknown {
    const obj: any = {};
    if (message.wgs84ZFrame !== 0) {
      obj.wgs84ZFrame = message.wgs84ZFrame;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FrameDetails_FrameAltitudeInfo>, I>>(base?: I): FrameDetails_FrameAltitudeInfo {
    return FrameDetails_FrameAltitudeInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FrameDetails_FrameAltitudeInfo>, I>>(
    object: I,
  ): FrameDetails_FrameAltitudeInfo {
    const message = createBaseFrameDetails_FrameAltitudeInfo();
    message.wgs84ZFrame = object.wgs84ZFrame ?? 0;
    return message;
  },
};

function createBaseFrame(): Frame {
  return { frameType: 0, frameDetails: undefined };
}

export const Frame = {
  encode(message: Frame, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.frameType !== 0) {
      writer.uint32(8).int32(message.frameType);
    }
    if (message.frameDetails !== undefined) {
      FrameDetails.encode(message.frameDetails, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Frame {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFrame();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.frameType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.frameDetails = FrameDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Frame {
    return {
      frameType: isSet(object.frameType) ? frameType_EnumFromJSON(object.frameType) : 0,
      frameDetails: isSet(object.frameDetails) ? FrameDetails.fromJSON(object.frameDetails) : undefined,
    };
  },

  toJSON(message: Frame): unknown {
    const obj: any = {};
    if (message.frameType !== 0) {
      obj.frameType = frameType_EnumToJSON(message.frameType);
    }
    if (message.frameDetails !== undefined) {
      obj.frameDetails = FrameDetails.toJSON(message.frameDetails);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Frame>, I>>(base?: I): Frame {
    return Frame.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Frame>, I>>(object: I): Frame {
    const message = createBaseFrame();
    message.frameType = object.frameType ?? 0;
    message.frameDetails = (object.frameDetails !== undefined && object.frameDetails !== null)
      ? FrameDetails.fromPartial(object.frameDetails)
      : undefined;
    return message;
  },
};

function createBasePositionXY(): PositionXY {
  return { x: 0, y: 0, frame: 0, frameDetails: undefined };
}

export const PositionXY = {
  encode(message: PositionXY, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== 0) {
      writer.uint32(9).double(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(17).double(message.y);
    }
    if (message.frame !== 0) {
      writer.uint32(24).int32(message.frame);
    }
    if (message.frameDetails !== undefined) {
      FrameDetails.encode(message.frameDetails, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PositionXY {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePositionXY();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.x = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.y = reader.double();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.frame = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.frameDetails = FrameDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PositionXY {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      frame: isSet(object.frame) ? frameType_EnumFromJSON(object.frame) : 0,
      frameDetails: isSet(object.frameDetails) ? FrameDetails.fromJSON(object.frameDetails) : undefined,
    };
  },

  toJSON(message: PositionXY): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    if (message.frame !== 0) {
      obj.frame = frameType_EnumToJSON(message.frame);
    }
    if (message.frameDetails !== undefined) {
      obj.frameDetails = FrameDetails.toJSON(message.frameDetails);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PositionXY>, I>>(base?: I): PositionXY {
    return PositionXY.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PositionXY>, I>>(object: I): PositionXY {
    const message = createBasePositionXY();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.frame = object.frame ?? 0;
    message.frameDetails = (object.frameDetails !== undefined && object.frameDetails !== null)
      ? FrameDetails.fromPartial(object.frameDetails)
      : undefined;
    return message;
  },
};

function createBasePositionZ(): PositionZ {
  return { value: 0, frame: 0, frameDetails: undefined };
}

export const PositionZ = {
  encode(message: PositionZ, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(13).float(message.value);
    }
    if (message.frame !== 0) {
      writer.uint32(16).int32(message.frame);
    }
    if (message.frameDetails !== undefined) {
      FrameDetails.encode(message.frameDetails, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PositionZ {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePositionZ();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.value = reader.float();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.frame = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.frameDetails = FrameDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PositionZ {
    return {
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
      frame: isSet(object.frame) ? frameType_EnumFromJSON(object.frame) : 0,
      frameDetails: isSet(object.frameDetails) ? FrameDetails.fromJSON(object.frameDetails) : undefined,
    };
  },

  toJSON(message: PositionZ): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    if (message.frame !== 0) {
      obj.frame = frameType_EnumToJSON(message.frame);
    }
    if (message.frameDetails !== undefined) {
      obj.frameDetails = FrameDetails.toJSON(message.frameDetails);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PositionZ>, I>>(base?: I): PositionZ {
    return PositionZ.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PositionZ>, I>>(object: I): PositionZ {
    const message = createBasePositionZ();
    message.value = object.value ?? 0;
    message.frame = object.frame ?? 0;
    message.frameDetails = (object.frameDetails !== undefined && object.frameDetails !== null)
      ? FrameDetails.fromPartial(object.frameDetails)
      : undefined;
    return message;
  },
};

function createBasePositionXYZ(): PositionXYZ {
  return { xy: undefined, z: undefined };
}

export const PositionXYZ = {
  encode(message: PositionXYZ, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.xy !== undefined) {
      PositionXY.encode(message.xy, writer.uint32(10).fork()).ldelim();
    }
    if (message.z !== undefined) {
      PositionZ.encode(message.z, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PositionXYZ {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePositionXYZ();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.xy = PositionXY.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.z = PositionZ.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PositionXYZ {
    return {
      xy: isSet(object.xy) ? PositionXY.fromJSON(object.xy) : undefined,
      z: isSet(object.z) ? PositionZ.fromJSON(object.z) : undefined,
    };
  },

  toJSON(message: PositionXYZ): unknown {
    const obj: any = {};
    if (message.xy !== undefined) {
      obj.xy = PositionXY.toJSON(message.xy);
    }
    if (message.z !== undefined) {
      obj.z = PositionZ.toJSON(message.z);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PositionXYZ>, I>>(base?: I): PositionXYZ {
    return PositionXYZ.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PositionXYZ>, I>>(object: I): PositionXYZ {
    const message = createBasePositionXYZ();
    message.xy = (object.xy !== undefined && object.xy !== null) ? PositionXY.fromPartial(object.xy) : undefined;
    message.z = (object.z !== undefined && object.z !== null) ? PositionZ.fromPartial(object.z) : undefined;
    return message;
  },
};

function createBaseHeading(): Heading {
  return { value: 0, frame: 0, frameDetails: undefined };
}

export const Heading = {
  encode(message: Heading, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(13).float(message.value);
    }
    if (message.frame !== 0) {
      writer.uint32(16).int32(message.frame);
    }
    if (message.frameDetails !== undefined) {
      FrameDetails.encode(message.frameDetails, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Heading {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHeading();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.value = reader.float();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.frame = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.frameDetails = FrameDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Heading {
    return {
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
      frame: isSet(object.frame) ? frameType_EnumFromJSON(object.frame) : 0,
      frameDetails: isSet(object.frameDetails) ? FrameDetails.fromJSON(object.frameDetails) : undefined,
    };
  },

  toJSON(message: Heading): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    if (message.frame !== 0) {
      obj.frame = frameType_EnumToJSON(message.frame);
    }
    if (message.frameDetails !== undefined) {
      obj.frameDetails = FrameDetails.toJSON(message.frameDetails);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Heading>, I>>(base?: I): Heading {
    return Heading.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Heading>, I>>(object: I): Heading {
    const message = createBaseHeading();
    message.value = object.value ?? 0;
    message.frame = object.frame ?? 0;
    message.frameDetails = (object.frameDetails !== undefined && object.frameDetails !== null)
      ? FrameDetails.fromPartial(object.frameDetails)
      : undefined;
    return message;
  },
};

function createBaseGimbalPitch(): GimbalPitch {
  return { value: 0 };
}

export const GimbalPitch = {
  encode(message: GimbalPitch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(13).float(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GimbalPitch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGimbalPitch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GimbalPitch {
    return { value: isSet(object.value) ? globalThis.Number(object.value) : 0 };
  },

  toJSON(message: GimbalPitch): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GimbalPitch>, I>>(base?: I): GimbalPitch {
    return GimbalPitch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GimbalPitch>, I>>(object: I): GimbalPitch {
    const message = createBaseGimbalPitch();
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseGimbalPitchRate(): GimbalPitchRate {
  return { value: 0, aggressiveness: 0, maxGimbalPitchRate: 0 };
}

export const GimbalPitchRate = {
  encode(message: GimbalPitchRate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(13).float(message.value);
    }
    if (message.aggressiveness !== 0) {
      writer.uint32(21).float(message.aggressiveness);
    }
    if (message.maxGimbalPitchRate !== 0) {
      writer.uint32(29).float(message.maxGimbalPitchRate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GimbalPitchRate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGimbalPitchRate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.value = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.aggressiveness = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.maxGimbalPitchRate = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GimbalPitchRate {
    return {
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
      aggressiveness: isSet(object.aggressiveness) ? globalThis.Number(object.aggressiveness) : 0,
      maxGimbalPitchRate: isSet(object.maxGimbalPitchRate) ? globalThis.Number(object.maxGimbalPitchRate) : 0,
    };
  },

  toJSON(message: GimbalPitchRate): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    if (message.aggressiveness !== 0) {
      obj.aggressiveness = message.aggressiveness;
    }
    if (message.maxGimbalPitchRate !== 0) {
      obj.maxGimbalPitchRate = message.maxGimbalPitchRate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GimbalPitchRate>, I>>(base?: I): GimbalPitchRate {
    return GimbalPitchRate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GimbalPitchRate>, I>>(object: I): GimbalPitchRate {
    const message = createBaseGimbalPitchRate();
    message.value = object.value ?? 0;
    message.aggressiveness = object.aggressiveness ?? 0;
    message.maxGimbalPitchRate = object.maxGimbalPitchRate ?? 0;
    return message;
  },
};

function createBaseHeadingRate(): HeadingRate {
  return { value: 0, aggressiveness: 0, maxHeadingRate: 0 };
}

export const HeadingRate = {
  encode(message: HeadingRate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(13).float(message.value);
    }
    if (message.aggressiveness !== 0) {
      writer.uint32(21).float(message.aggressiveness);
    }
    if (message.maxHeadingRate !== 0) {
      writer.uint32(29).float(message.maxHeadingRate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HeadingRate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHeadingRate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.value = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.aggressiveness = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.maxHeadingRate = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HeadingRate {
    return {
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
      aggressiveness: isSet(object.aggressiveness) ? globalThis.Number(object.aggressiveness) : 0,
      maxHeadingRate: isSet(object.maxHeadingRate) ? globalThis.Number(object.maxHeadingRate) : 0,
    };
  },

  toJSON(message: HeadingRate): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    if (message.aggressiveness !== 0) {
      obj.aggressiveness = message.aggressiveness;
    }
    if (message.maxHeadingRate !== 0) {
      obj.maxHeadingRate = message.maxHeadingRate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HeadingRate>, I>>(base?: I): HeadingRate {
    return HeadingRate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HeadingRate>, I>>(object: I): HeadingRate {
    const message = createBaseHeadingRate();
    message.value = object.value ?? 0;
    message.aggressiveness = object.aggressiveness ?? 0;
    message.maxHeadingRate = object.maxHeadingRate ?? 0;
    return message;
  },
};

function createBaseLookAtCommand(): LookAtCommand {
  return {
    utime: 0,
    heading: undefined,
    gimbalPitch: undefined,
    gimbalPitchRate: undefined,
    headingRate: undefined,
    source: 0,
    enforceTimeout: false,
  };
}

export const LookAtCommand = {
  encode(message: LookAtCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    if (message.heading !== undefined) {
      Heading.encode(message.heading, writer.uint32(18).fork()).ldelim();
    }
    if (message.gimbalPitch !== undefined) {
      GimbalPitch.encode(message.gimbalPitch, writer.uint32(26).fork()).ldelim();
    }
    if (message.gimbalPitchRate !== undefined) {
      GimbalPitchRate.encode(message.gimbalPitchRate, writer.uint32(58).fork()).ldelim();
    }
    if (message.headingRate !== undefined) {
      HeadingRate.encode(message.headingRate, writer.uint32(42).fork()).ldelim();
    }
    if (message.source !== 0) {
      writer.uint32(32).int32(message.source);
    }
    if (message.enforceTimeout === true) {
      writer.uint32(48).bool(message.enforceTimeout);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookAtCommand {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookAtCommand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.heading = Heading.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.gimbalPitch = GimbalPitch.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.gimbalPitchRate = GimbalPitchRate.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.headingRate = HeadingRate.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enforceTimeout = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookAtCommand {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      heading: isSet(object.heading) ? Heading.fromJSON(object.heading) : undefined,
      gimbalPitch: isSet(object.gimbalPitch) ? GimbalPitch.fromJSON(object.gimbalPitch) : undefined,
      gimbalPitchRate: isSet(object.gimbalPitchRate) ? GimbalPitchRate.fromJSON(object.gimbalPitchRate) : undefined,
      headingRate: isSet(object.headingRate) ? HeadingRate.fromJSON(object.headingRate) : undefined,
      source: isSet(object.source) ? goToWaypointSource_EnumFromJSON(object.source) : 0,
      enforceTimeout: isSet(object.enforceTimeout) ? globalThis.Boolean(object.enforceTimeout) : false,
    };
  },

  toJSON(message: LookAtCommand): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.heading !== undefined) {
      obj.heading = Heading.toJSON(message.heading);
    }
    if (message.gimbalPitch !== undefined) {
      obj.gimbalPitch = GimbalPitch.toJSON(message.gimbalPitch);
    }
    if (message.gimbalPitchRate !== undefined) {
      obj.gimbalPitchRate = GimbalPitchRate.toJSON(message.gimbalPitchRate);
    }
    if (message.headingRate !== undefined) {
      obj.headingRate = HeadingRate.toJSON(message.headingRate);
    }
    if (message.source !== 0) {
      obj.source = goToWaypointSource_EnumToJSON(message.source);
    }
    if (message.enforceTimeout === true) {
      obj.enforceTimeout = message.enforceTimeout;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookAtCommand>, I>>(base?: I): LookAtCommand {
    return LookAtCommand.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookAtCommand>, I>>(object: I): LookAtCommand {
    const message = createBaseLookAtCommand();
    message.utime = object.utime ?? 0;
    message.heading = (object.heading !== undefined && object.heading !== null)
      ? Heading.fromPartial(object.heading)
      : undefined;
    message.gimbalPitch = (object.gimbalPitch !== undefined && object.gimbalPitch !== null)
      ? GimbalPitch.fromPartial(object.gimbalPitch)
      : undefined;
    message.gimbalPitchRate = (object.gimbalPitchRate !== undefined && object.gimbalPitchRate !== null)
      ? GimbalPitchRate.fromPartial(object.gimbalPitchRate)
      : undefined;
    message.headingRate = (object.headingRate !== undefined && object.headingRate !== null)
      ? HeadingRate.fromPartial(object.headingRate)
      : undefined;
    message.source = object.source ?? 0;
    message.enforceTimeout = object.enforceTimeout ?? false;
    return message;
  },
};

function createBaseWaypoint(): Waypoint {
  return { xy: undefined, z: undefined, heading: undefined, gimbalPitch: undefined };
}

export const Waypoint = {
  encode(message: Waypoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.xy !== undefined) {
      PositionXY.encode(message.xy, writer.uint32(10).fork()).ldelim();
    }
    if (message.z !== undefined) {
      PositionZ.encode(message.z, writer.uint32(18).fork()).ldelim();
    }
    if (message.heading !== undefined) {
      Heading.encode(message.heading, writer.uint32(26).fork()).ldelim();
    }
    if (message.gimbalPitch !== undefined) {
      GimbalPitch.encode(message.gimbalPitch, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Waypoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWaypoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.xy = PositionXY.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.z = PositionZ.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.heading = Heading.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.gimbalPitch = GimbalPitch.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Waypoint {
    return {
      xy: isSet(object.xy) ? PositionXY.fromJSON(object.xy) : undefined,
      z: isSet(object.z) ? PositionZ.fromJSON(object.z) : undefined,
      heading: isSet(object.heading) ? Heading.fromJSON(object.heading) : undefined,
      gimbalPitch: isSet(object.gimbalPitch) ? GimbalPitch.fromJSON(object.gimbalPitch) : undefined,
    };
  },

  toJSON(message: Waypoint): unknown {
    const obj: any = {};
    if (message.xy !== undefined) {
      obj.xy = PositionXY.toJSON(message.xy);
    }
    if (message.z !== undefined) {
      obj.z = PositionZ.toJSON(message.z);
    }
    if (message.heading !== undefined) {
      obj.heading = Heading.toJSON(message.heading);
    }
    if (message.gimbalPitch !== undefined) {
      obj.gimbalPitch = GimbalPitch.toJSON(message.gimbalPitch);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Waypoint>, I>>(base?: I): Waypoint {
    return Waypoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Waypoint>, I>>(object: I): Waypoint {
    const message = createBaseWaypoint();
    message.xy = (object.xy !== undefined && object.xy !== null) ? PositionXY.fromPartial(object.xy) : undefined;
    message.z = (object.z !== undefined && object.z !== null) ? PositionZ.fromPartial(object.z) : undefined;
    message.heading = (object.heading !== undefined && object.heading !== null)
      ? Heading.fromPartial(object.heading)
      : undefined;
    message.gimbalPitch = (object.gimbalPitch !== undefined && object.gimbalPitch !== null)
      ? GimbalPitch.fromPartial(object.gimbalPitch)
      : undefined;
    return message;
  },
};

function createBasePositionWaypoint(): PositionWaypoint {
  return { xy: undefined, z: undefined };
}

export const PositionWaypoint = {
  encode(message: PositionWaypoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.xy !== undefined) {
      PositionXY.encode(message.xy, writer.uint32(10).fork()).ldelim();
    }
    if (message.z !== undefined) {
      PositionZ.encode(message.z, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PositionWaypoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePositionWaypoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.xy = PositionXY.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.z = PositionZ.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PositionWaypoint {
    return {
      xy: isSet(object.xy) ? PositionXY.fromJSON(object.xy) : undefined,
      z: isSet(object.z) ? PositionZ.fromJSON(object.z) : undefined,
    };
  },

  toJSON(message: PositionWaypoint): unknown {
    const obj: any = {};
    if (message.xy !== undefined) {
      obj.xy = PositionXY.toJSON(message.xy);
    }
    if (message.z !== undefined) {
      obj.z = PositionZ.toJSON(message.z);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PositionWaypoint>, I>>(base?: I): PositionWaypoint {
    return PositionWaypoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PositionWaypoint>, I>>(object: I): PositionWaypoint {
    const message = createBasePositionWaypoint();
    message.xy = (object.xy !== undefined && object.xy !== null) ? PositionXY.fromPartial(object.xy) : undefined;
    message.z = (object.z !== undefined && object.z !== null) ? PositionZ.fromPartial(object.z) : undefined;
    return message;
  },
};

function createBaseLookAtWaypoint(): LookAtWaypoint {
  return {
    waypoint: undefined,
    lookAtPoint: undefined,
    useVerticalLookAtHeading: false,
    verticalLookAtHeading: undefined,
  };
}

export const LookAtWaypoint = {
  encode(message: LookAtWaypoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.waypoint !== undefined) {
      PositionWaypoint.encode(message.waypoint, writer.uint32(10).fork()).ldelim();
    }
    if (message.lookAtPoint !== undefined) {
      PositionWaypoint.encode(message.lookAtPoint, writer.uint32(18).fork()).ldelim();
    }
    if (message.useVerticalLookAtHeading === true) {
      writer.uint32(24).bool(message.useVerticalLookAtHeading);
    }
    if (message.verticalLookAtHeading !== undefined) {
      Heading.encode(message.verticalLookAtHeading, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookAtWaypoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookAtWaypoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.waypoint = PositionWaypoint.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lookAtPoint = PositionWaypoint.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.useVerticalLookAtHeading = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.verticalLookAtHeading = Heading.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookAtWaypoint {
    return {
      waypoint: isSet(object.waypoint) ? PositionWaypoint.fromJSON(object.waypoint) : undefined,
      lookAtPoint: isSet(object.lookAtPoint) ? PositionWaypoint.fromJSON(object.lookAtPoint) : undefined,
      useVerticalLookAtHeading: isSet(object.useVerticalLookAtHeading)
        ? globalThis.Boolean(object.useVerticalLookAtHeading)
        : false,
      verticalLookAtHeading: isSet(object.verticalLookAtHeading)
        ? Heading.fromJSON(object.verticalLookAtHeading)
        : undefined,
    };
  },

  toJSON(message: LookAtWaypoint): unknown {
    const obj: any = {};
    if (message.waypoint !== undefined) {
      obj.waypoint = PositionWaypoint.toJSON(message.waypoint);
    }
    if (message.lookAtPoint !== undefined) {
      obj.lookAtPoint = PositionWaypoint.toJSON(message.lookAtPoint);
    }
    if (message.useVerticalLookAtHeading === true) {
      obj.useVerticalLookAtHeading = message.useVerticalLookAtHeading;
    }
    if (message.verticalLookAtHeading !== undefined) {
      obj.verticalLookAtHeading = Heading.toJSON(message.verticalLookAtHeading);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookAtWaypoint>, I>>(base?: I): LookAtWaypoint {
    return LookAtWaypoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookAtWaypoint>, I>>(object: I): LookAtWaypoint {
    const message = createBaseLookAtWaypoint();
    message.waypoint = (object.waypoint !== undefined && object.waypoint !== null)
      ? PositionWaypoint.fromPartial(object.waypoint)
      : undefined;
    message.lookAtPoint = (object.lookAtPoint !== undefined && object.lookAtPoint !== null)
      ? PositionWaypoint.fromPartial(object.lookAtPoint)
      : undefined;
    message.useVerticalLookAtHeading = object.useVerticalLookAtHeading ?? false;
    message.verticalLookAtHeading =
      (object.verticalLookAtHeading !== undefined && object.verticalLookAtHeading !== null)
        ? Heading.fromPartial(object.verticalLookAtHeading)
        : undefined;
    return message;
  },
};

function createBaseDockWaypointEstimate(): DockWaypointEstimate {
  return { source: 0, dockWaypoint: undefined, uncertainty: 0, navTWaypoint: undefined, dockWaypointGps: undefined };
}

export const DockWaypointEstimate = {
  encode(message: DockWaypointEstimate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.source !== 0) {
      writer.uint32(8).int32(message.source);
    }
    if (message.dockWaypoint !== undefined) {
      Waypoint.encode(message.dockWaypoint, writer.uint32(18).fork()).ldelim();
    }
    if (message.uncertainty !== 0) {
      writer.uint32(25).double(message.uncertainty);
    }
    if (message.navTWaypoint !== undefined) {
      TransPb.encode(message.navTWaypoint, writer.uint32(34).fork()).ldelim();
    }
    if (message.dockWaypointGps !== undefined) {
      Waypoint.encode(message.dockWaypointGps, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DockWaypointEstimate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDockWaypointEstimate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dockWaypoint = Waypoint.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.uncertainty = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.navTWaypoint = TransPb.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.dockWaypointGps = Waypoint.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DockWaypointEstimate {
    return {
      source: isSet(object.source) ? landingPadAnchorPoseSource_EnumFromJSON(object.source) : 0,
      dockWaypoint: isSet(object.dockWaypoint) ? Waypoint.fromJSON(object.dockWaypoint) : undefined,
      uncertainty: isSet(object.uncertainty) ? globalThis.Number(object.uncertainty) : 0,
      navTWaypoint: isSet(object.navTWaypoint) ? TransPb.fromJSON(object.navTWaypoint) : undefined,
      dockWaypointGps: isSet(object.dockWaypointGps) ? Waypoint.fromJSON(object.dockWaypointGps) : undefined,
    };
  },

  toJSON(message: DockWaypointEstimate): unknown {
    const obj: any = {};
    if (message.source !== 0) {
      obj.source = landingPadAnchorPoseSource_EnumToJSON(message.source);
    }
    if (message.dockWaypoint !== undefined) {
      obj.dockWaypoint = Waypoint.toJSON(message.dockWaypoint);
    }
    if (message.uncertainty !== 0) {
      obj.uncertainty = message.uncertainty;
    }
    if (message.navTWaypoint !== undefined) {
      obj.navTWaypoint = TransPb.toJSON(message.navTWaypoint);
    }
    if (message.dockWaypointGps !== undefined) {
      obj.dockWaypointGps = Waypoint.toJSON(message.dockWaypointGps);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DockWaypointEstimate>, I>>(base?: I): DockWaypointEstimate {
    return DockWaypointEstimate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DockWaypointEstimate>, I>>(object: I): DockWaypointEstimate {
    const message = createBaseDockWaypointEstimate();
    message.source = object.source ?? 0;
    message.dockWaypoint = (object.dockWaypoint !== undefined && object.dockWaypoint !== null)
      ? Waypoint.fromPartial(object.dockWaypoint)
      : undefined;
    message.uncertainty = object.uncertainty ?? 0;
    message.navTWaypoint = (object.navTWaypoint !== undefined && object.navTWaypoint !== null)
      ? TransPb.fromPartial(object.navTWaypoint)
      : undefined;
    message.dockWaypointGps = (object.dockWaypointGps !== undefined && object.dockWaypointGps !== null)
      ? Waypoint.fromPartial(object.dockWaypointGps)
      : undefined;
    return message;
  },
};

function createBaseDockWaypointList(): DockWaypointList {
  return { utime: 0, dockWaypointEstimates: [], selectedSource: 0 };
}

export const DockWaypointList = {
  encode(message: DockWaypointList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    for (const v of message.dockWaypointEstimates) {
      DockWaypointEstimate.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.selectedSource !== 0) {
      writer.uint32(24).int32(message.selectedSource);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DockWaypointList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDockWaypointList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dockWaypointEstimates.push(DockWaypointEstimate.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.selectedSource = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DockWaypointList {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      dockWaypointEstimates: globalThis.Array.isArray(object?.dockWaypointEstimates)
        ? object.dockWaypointEstimates.map((e: any) => DockWaypointEstimate.fromJSON(e))
        : [],
      selectedSource: isSet(object.selectedSource) ? landingPadAnchorPoseSource_EnumFromJSON(object.selectedSource) : 0,
    };
  },

  toJSON(message: DockWaypointList): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.dockWaypointEstimates?.length) {
      obj.dockWaypointEstimates = message.dockWaypointEstimates.map((e) => DockWaypointEstimate.toJSON(e));
    }
    if (message.selectedSource !== 0) {
      obj.selectedSource = landingPadAnchorPoseSource_EnumToJSON(message.selectedSource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DockWaypointList>, I>>(base?: I): DockWaypointList {
    return DockWaypointList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DockWaypointList>, I>>(object: I): DockWaypointList {
    const message = createBaseDockWaypointList();
    message.utime = object.utime ?? 0;
    message.dockWaypointEstimates = object.dockWaypointEstimates?.map((e) => DockWaypointEstimate.fromPartial(e)) || [];
    message.selectedSource = object.selectedSource ?? 0;
    return message;
  },
};

function createBaseDistAndTimeToTargetWaypoint(): DistAndTimeToTargetWaypoint {
  return { distance: 0, time: 0, motionArgs: undefined, flightEnergyRequired: 0, additionalAscent: 0 };
}

export const DistAndTimeToTargetWaypoint = {
  encode(message: DistAndTimeToTargetWaypoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.distance !== 0) {
      writer.uint32(13).float(message.distance);
    }
    if (message.time !== 0) {
      writer.uint32(21).float(message.time);
    }
    if (message.motionArgs !== undefined) {
      MotionArgs.encode(message.motionArgs, writer.uint32(26).fork()).ldelim();
    }
    if (message.flightEnergyRequired !== 0) {
      writer.uint32(37).float(message.flightEnergyRequired);
    }
    if (message.additionalAscent !== 0) {
      writer.uint32(45).float(message.additionalAscent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DistAndTimeToTargetWaypoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDistAndTimeToTargetWaypoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.distance = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.time = reader.float();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.motionArgs = MotionArgs.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.flightEnergyRequired = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.additionalAscent = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DistAndTimeToTargetWaypoint {
    return {
      distance: isSet(object.distance) ? globalThis.Number(object.distance) : 0,
      time: isSet(object.time) ? globalThis.Number(object.time) : 0,
      motionArgs: isSet(object.motionArgs) ? MotionArgs.fromJSON(object.motionArgs) : undefined,
      flightEnergyRequired: isSet(object.flightEnergyRequired) ? globalThis.Number(object.flightEnergyRequired) : 0,
      additionalAscent: isSet(object.additionalAscent) ? globalThis.Number(object.additionalAscent) : 0,
    };
  },

  toJSON(message: DistAndTimeToTargetWaypoint): unknown {
    const obj: any = {};
    if (message.distance !== 0) {
      obj.distance = message.distance;
    }
    if (message.time !== 0) {
      obj.time = message.time;
    }
    if (message.motionArgs !== undefined) {
      obj.motionArgs = MotionArgs.toJSON(message.motionArgs);
    }
    if (message.flightEnergyRequired !== 0) {
      obj.flightEnergyRequired = message.flightEnergyRequired;
    }
    if (message.additionalAscent !== 0) {
      obj.additionalAscent = message.additionalAscent;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DistAndTimeToTargetWaypoint>, I>>(base?: I): DistAndTimeToTargetWaypoint {
    return DistAndTimeToTargetWaypoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DistAndTimeToTargetWaypoint>, I>>(object: I): DistAndTimeToTargetWaypoint {
    const message = createBaseDistAndTimeToTargetWaypoint();
    message.distance = object.distance ?? 0;
    message.time = object.time ?? 0;
    message.motionArgs = (object.motionArgs !== undefined && object.motionArgs !== null)
      ? MotionArgs.fromPartial(object.motionArgs)
      : undefined;
    message.flightEnergyRequired = object.flightEnergyRequired ?? 0;
    message.additionalAscent = object.additionalAscent ?? 0;
    return message;
  },
};

function createBaseTargetWaypointData(): TargetWaypointData {
  return {
    waypoint: undefined,
    utimeWhenRecorded: 0,
    euclideanDistance3d: 0,
    euclideanDistance2d: 0,
    returnDistAndTime: undefined,
    isReachable: false,
    waypointInGps: undefined,
  };
}

export const TargetWaypointData = {
  encode(message: TargetWaypointData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.waypoint !== undefined) {
      Waypoint.encode(message.waypoint, writer.uint32(10).fork()).ldelim();
    }
    if (message.utimeWhenRecorded !== 0) {
      writer.uint32(16).int64(message.utimeWhenRecorded);
    }
    if (message.euclideanDistance3d !== 0) {
      writer.uint32(25).double(message.euclideanDistance3d);
    }
    if (message.euclideanDistance2d !== 0) {
      writer.uint32(33).double(message.euclideanDistance2d);
    }
    if (message.returnDistAndTime !== undefined) {
      DistAndTimeToTargetWaypoint.encode(message.returnDistAndTime, writer.uint32(42).fork()).ldelim();
    }
    if (message.isReachable === true) {
      writer.uint32(48).bool(message.isReachable);
    }
    if (message.waypointInGps !== undefined) {
      Waypoint.encode(message.waypointInGps, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TargetWaypointData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTargetWaypointData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.waypoint = Waypoint.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.utimeWhenRecorded = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.euclideanDistance3d = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.euclideanDistance2d = reader.double();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.returnDistAndTime = DistAndTimeToTargetWaypoint.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isReachable = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.waypointInGps = Waypoint.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TargetWaypointData {
    return {
      waypoint: isSet(object.waypoint) ? Waypoint.fromJSON(object.waypoint) : undefined,
      utimeWhenRecorded: isSet(object.utimeWhenRecorded) ? globalThis.Number(object.utimeWhenRecorded) : 0,
      euclideanDistance3d: isSet(object.euclideanDistance3d) ? globalThis.Number(object.euclideanDistance3d) : 0,
      euclideanDistance2d: isSet(object.euclideanDistance2d) ? globalThis.Number(object.euclideanDistance2d) : 0,
      returnDistAndTime: isSet(object.returnDistAndTime)
        ? DistAndTimeToTargetWaypoint.fromJSON(object.returnDistAndTime)
        : undefined,
      isReachable: isSet(object.isReachable) ? globalThis.Boolean(object.isReachable) : false,
      waypointInGps: isSet(object.waypointInGps) ? Waypoint.fromJSON(object.waypointInGps) : undefined,
    };
  },

  toJSON(message: TargetWaypointData): unknown {
    const obj: any = {};
    if (message.waypoint !== undefined) {
      obj.waypoint = Waypoint.toJSON(message.waypoint);
    }
    if (message.utimeWhenRecorded !== 0) {
      obj.utimeWhenRecorded = Math.round(message.utimeWhenRecorded);
    }
    if (message.euclideanDistance3d !== 0) {
      obj.euclideanDistance3d = message.euclideanDistance3d;
    }
    if (message.euclideanDistance2d !== 0) {
      obj.euclideanDistance2d = message.euclideanDistance2d;
    }
    if (message.returnDistAndTime !== undefined) {
      obj.returnDistAndTime = DistAndTimeToTargetWaypoint.toJSON(message.returnDistAndTime);
    }
    if (message.isReachable === true) {
      obj.isReachable = message.isReachable;
    }
    if (message.waypointInGps !== undefined) {
      obj.waypointInGps = Waypoint.toJSON(message.waypointInGps);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TargetWaypointData>, I>>(base?: I): TargetWaypointData {
    return TargetWaypointData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TargetWaypointData>, I>>(object: I): TargetWaypointData {
    const message = createBaseTargetWaypointData();
    message.waypoint = (object.waypoint !== undefined && object.waypoint !== null)
      ? Waypoint.fromPartial(object.waypoint)
      : undefined;
    message.utimeWhenRecorded = object.utimeWhenRecorded ?? 0;
    message.euclideanDistance3d = object.euclideanDistance3d ?? 0;
    message.euclideanDistance2d = object.euclideanDistance2d ?? 0;
    message.returnDistAndTime = (object.returnDistAndTime !== undefined && object.returnDistAndTime !== null)
      ? DistAndTimeToTargetWaypoint.fromPartial(object.returnDistAndTime)
      : undefined;
    message.isReachable = object.isReachable ?? false;
    message.waypointInGps = (object.waypointInGps !== undefined && object.waypointInGps !== null)
      ? Waypoint.fromPartial(object.waypointInGps)
      : undefined;
    return message;
  },
};

function createBasePilot(): Pilot {
  return { waypointKey: 0, clientType: 0 };
}

export const Pilot = {
  encode(message: Pilot, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.waypointKey !== 0) {
      writer.uint32(8).int64(message.waypointKey);
    }
    if (message.clientType !== 0) {
      writer.uint32(16).int32(message.clientType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Pilot {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePilot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.waypointKey = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.clientType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Pilot {
    return {
      waypointKey: isSet(object.waypointKey) ? globalThis.Number(object.waypointKey) : 0,
      clientType: isSet(object.clientType) ? clientType_EnumFromJSON(object.clientType) : 0,
    };
  },

  toJSON(message: Pilot): unknown {
    const obj: any = {};
    if (message.waypointKey !== 0) {
      obj.waypointKey = Math.round(message.waypointKey);
    }
    if (message.clientType !== 0) {
      obj.clientType = clientType_EnumToJSON(message.clientType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Pilot>, I>>(base?: I): Pilot {
    return Pilot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Pilot>, I>>(object: I): Pilot {
    const message = createBasePilot();
    message.waypointKey = object.waypointKey ?? 0;
    message.clientType = object.clientType ?? 0;
    return message;
  },
};

function createBaseWaypointsList(): WaypointsList {
  return {
    utime: 0,
    waypointData: {},
    pilot: undefined,
    criticalRtxDestinations: {},
    loadedFromFile: false,
    backupLostCommsReturnHeadingNav: 0,
    flightId: "",
    nonce: 0,
    batteryRange: 0,
    batteryRangeIsValid: false,
  };
}

export const WaypointsList = {
  encode(message: WaypointsList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    Object.entries(message.waypointData).forEach(([key, value]) => {
      WaypointsList_WaypointDataEntry.encode({ key: key as any, value }, writer.uint32(50).fork()).ldelim();
    });
    if (message.pilot !== undefined) {
      Pilot.encode(message.pilot, writer.uint32(58).fork()).ldelim();
    }
    Object.entries(message.criticalRtxDestinations).forEach(([key, value]) => {
      WaypointsList_CriticalRtxDestinationsEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).ldelim();
    });
    if (message.loadedFromFile === true) {
      writer.uint32(72).bool(message.loadedFromFile);
    }
    if (message.backupLostCommsReturnHeadingNav !== 0) {
      writer.uint32(85).float(message.backupLostCommsReturnHeadingNav);
    }
    if (message.flightId !== "") {
      writer.uint32(90).string(message.flightId);
    }
    if (message.nonce !== 0) {
      writer.uint32(96).int64(message.nonce);
    }
    if (message.batteryRange !== 0) {
      writer.uint32(109).float(message.batteryRange);
    }
    if (message.batteryRangeIsValid === true) {
      writer.uint32(112).bool(message.batteryRangeIsValid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WaypointsList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWaypointsList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = WaypointsList_WaypointDataEntry.decode(reader, reader.uint32());
          if (entry6.value !== undefined) {
            message.waypointData[entry6.key] = entry6.value;
          }
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.pilot = Pilot.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = WaypointsList_CriticalRtxDestinationsEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.criticalRtxDestinations[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.loadedFromFile = reader.bool();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.backupLostCommsReturnHeadingNav = reader.float();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.flightId = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.nonce = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 109) {
            break;
          }

          message.batteryRange = reader.float();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.batteryRangeIsValid = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WaypointsList {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      waypointData: isObject(object.waypointData)
        ? Object.entries(object.waypointData).reduce<{ [key: number]: TargetWaypointData }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = TargetWaypointData.fromJSON(value);
          return acc;
        }, {})
        : {},
      pilot: isSet(object.pilot) ? Pilot.fromJSON(object.pilot) : undefined,
      criticalRtxDestinations: isObject(object.criticalRtxDestinations)
        ? Object.entries(object.criticalRtxDestinations).reduce<{ [key: number]: number }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = Number(value);
          return acc;
        }, {})
        : {},
      loadedFromFile: isSet(object.loadedFromFile) ? globalThis.Boolean(object.loadedFromFile) : false,
      backupLostCommsReturnHeadingNav: isSet(object.backupLostCommsReturnHeadingNav)
        ? globalThis.Number(object.backupLostCommsReturnHeadingNav)
        : 0,
      flightId: isSet(object.flightId) ? globalThis.String(object.flightId) : "",
      nonce: isSet(object.nonce) ? globalThis.Number(object.nonce) : 0,
      batteryRange: isSet(object.batteryRange) ? globalThis.Number(object.batteryRange) : 0,
      batteryRangeIsValid: isSet(object.batteryRangeIsValid) ? globalThis.Boolean(object.batteryRangeIsValid) : false,
    };
  },

  toJSON(message: WaypointsList): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.waypointData) {
      const entries = Object.entries(message.waypointData);
      if (entries.length > 0) {
        obj.waypointData = {};
        entries.forEach(([k, v]) => {
          obj.waypointData[k] = TargetWaypointData.toJSON(v);
        });
      }
    }
    if (message.pilot !== undefined) {
      obj.pilot = Pilot.toJSON(message.pilot);
    }
    if (message.criticalRtxDestinations) {
      const entries = Object.entries(message.criticalRtxDestinations);
      if (entries.length > 0) {
        obj.criticalRtxDestinations = {};
        entries.forEach(([k, v]) => {
          obj.criticalRtxDestinations[k] = Math.round(v);
        });
      }
    }
    if (message.loadedFromFile === true) {
      obj.loadedFromFile = message.loadedFromFile;
    }
    if (message.backupLostCommsReturnHeadingNav !== 0) {
      obj.backupLostCommsReturnHeadingNav = message.backupLostCommsReturnHeadingNav;
    }
    if (message.flightId !== "") {
      obj.flightId = message.flightId;
    }
    if (message.nonce !== 0) {
      obj.nonce = Math.round(message.nonce);
    }
    if (message.batteryRange !== 0) {
      obj.batteryRange = message.batteryRange;
    }
    if (message.batteryRangeIsValid === true) {
      obj.batteryRangeIsValid = message.batteryRangeIsValid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WaypointsList>, I>>(base?: I): WaypointsList {
    return WaypointsList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WaypointsList>, I>>(object: I): WaypointsList {
    const message = createBaseWaypointsList();
    message.utime = object.utime ?? 0;
    message.waypointData = Object.entries(object.waypointData ?? {}).reduce<{ [key: number]: TargetWaypointData }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = TargetWaypointData.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.pilot = (object.pilot !== undefined && object.pilot !== null) ? Pilot.fromPartial(object.pilot) : undefined;
    message.criticalRtxDestinations = Object.entries(object.criticalRtxDestinations ?? {}).reduce<
      { [key: number]: number }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = globalThis.Number(value);
      }
      return acc;
    }, {});
    message.loadedFromFile = object.loadedFromFile ?? false;
    message.backupLostCommsReturnHeadingNav = object.backupLostCommsReturnHeadingNav ?? 0;
    message.flightId = object.flightId ?? "";
    message.nonce = object.nonce ?? 0;
    message.batteryRange = object.batteryRange ?? 0;
    message.batteryRangeIsValid = object.batteryRangeIsValid ?? false;
    return message;
  },
};

function createBaseWaypointsList_WaypointDataEntry(): WaypointsList_WaypointDataEntry {
  return { key: 0, value: undefined };
}

export const WaypointsList_WaypointDataEntry = {
  encode(message: WaypointsList_WaypointDataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int64(message.key);
    }
    if (message.value !== undefined) {
      TargetWaypointData.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WaypointsList_WaypointDataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWaypointsList_WaypointDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = TargetWaypointData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WaypointsList_WaypointDataEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? TargetWaypointData.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: WaypointsList_WaypointDataEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = TargetWaypointData.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WaypointsList_WaypointDataEntry>, I>>(base?: I): WaypointsList_WaypointDataEntry {
    return WaypointsList_WaypointDataEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WaypointsList_WaypointDataEntry>, I>>(
    object: I,
  ): WaypointsList_WaypointDataEntry {
    const message = createBaseWaypointsList_WaypointDataEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? TargetWaypointData.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseWaypointsList_CriticalRtxDestinationsEntry(): WaypointsList_CriticalRtxDestinationsEntry {
  return { key: 0, value: 0 };
}

export const WaypointsList_CriticalRtxDestinationsEntry = {
  encode(message: WaypointsList_CriticalRtxDestinationsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int64(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WaypointsList_CriticalRtxDestinationsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWaypointsList_CriticalRtxDestinationsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WaypointsList_CriticalRtxDestinationsEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: WaypointsList_CriticalRtxDestinationsEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WaypointsList_CriticalRtxDestinationsEntry>, I>>(
    base?: I,
  ): WaypointsList_CriticalRtxDestinationsEntry {
    return WaypointsList_CriticalRtxDestinationsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WaypointsList_CriticalRtxDestinationsEntry>, I>>(
    object: I,
  ): WaypointsList_CriticalRtxDestinationsEntry {
    const message = createBaseWaypointsList_CriticalRtxDestinationsEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseDistanceDataForWireless(): DistanceDataForWireless {
  return { utime: 0, controllerData: undefined, externalRadioData: undefined, dockData: undefined };
}

export const DistanceDataForWireless = {
  encode(message: DistanceDataForWireless, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    if (message.controllerData !== undefined) {
      DistanceDataForWireless_Data.encode(message.controllerData, writer.uint32(18).fork()).ldelim();
    }
    if (message.externalRadioData !== undefined) {
      DistanceDataForWireless_Data.encode(message.externalRadioData, writer.uint32(26).fork()).ldelim();
    }
    if (message.dockData !== undefined) {
      DistanceDataForWireless_Data.encode(message.dockData, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DistanceDataForWireless {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDistanceDataForWireless();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.controllerData = DistanceDataForWireless_Data.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.externalRadioData = DistanceDataForWireless_Data.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dockData = DistanceDataForWireless_Data.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DistanceDataForWireless {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      controllerData: isSet(object.controllerData)
        ? DistanceDataForWireless_Data.fromJSON(object.controllerData)
        : undefined,
      externalRadioData: isSet(object.externalRadioData)
        ? DistanceDataForWireless_Data.fromJSON(object.externalRadioData)
        : undefined,
      dockData: isSet(object.dockData) ? DistanceDataForWireless_Data.fromJSON(object.dockData) : undefined,
    };
  },

  toJSON(message: DistanceDataForWireless): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.controllerData !== undefined) {
      obj.controllerData = DistanceDataForWireless_Data.toJSON(message.controllerData);
    }
    if (message.externalRadioData !== undefined) {
      obj.externalRadioData = DistanceDataForWireless_Data.toJSON(message.externalRadioData);
    }
    if (message.dockData !== undefined) {
      obj.dockData = DistanceDataForWireless_Data.toJSON(message.dockData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DistanceDataForWireless>, I>>(base?: I): DistanceDataForWireless {
    return DistanceDataForWireless.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DistanceDataForWireless>, I>>(object: I): DistanceDataForWireless {
    const message = createBaseDistanceDataForWireless();
    message.utime = object.utime ?? 0;
    message.controllerData = (object.controllerData !== undefined && object.controllerData !== null)
      ? DistanceDataForWireless_Data.fromPartial(object.controllerData)
      : undefined;
    message.externalRadioData = (object.externalRadioData !== undefined && object.externalRadioData !== null)
      ? DistanceDataForWireless_Data.fromPartial(object.externalRadioData)
      : undefined;
    message.dockData = (object.dockData !== undefined && object.dockData !== null)
      ? DistanceDataForWireless_Data.fromPartial(object.dockData)
      : undefined;
    return message;
  },
};

function createBaseDistanceDataForWireless_Data(): DistanceDataForWireless_Data {
  return { distance: 0, usingTakeoffAsBackup: false, stale: false };
}

export const DistanceDataForWireless_Data = {
  encode(message: DistanceDataForWireless_Data, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.distance !== 0) {
      writer.uint32(13).float(message.distance);
    }
    if (message.usingTakeoffAsBackup === true) {
      writer.uint32(16).bool(message.usingTakeoffAsBackup);
    }
    if (message.stale === true) {
      writer.uint32(24).bool(message.stale);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DistanceDataForWireless_Data {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDistanceDataForWireless_Data();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.distance = reader.float();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.usingTakeoffAsBackup = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.stale = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DistanceDataForWireless_Data {
    return {
      distance: isSet(object.distance) ? globalThis.Number(object.distance) : 0,
      usingTakeoffAsBackup: isSet(object.usingTakeoffAsBackup)
        ? globalThis.Boolean(object.usingTakeoffAsBackup)
        : false,
      stale: isSet(object.stale) ? globalThis.Boolean(object.stale) : false,
    };
  },

  toJSON(message: DistanceDataForWireless_Data): unknown {
    const obj: any = {};
    if (message.distance !== 0) {
      obj.distance = message.distance;
    }
    if (message.usingTakeoffAsBackup === true) {
      obj.usingTakeoffAsBackup = message.usingTakeoffAsBackup;
    }
    if (message.stale === true) {
      obj.stale = message.stale;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DistanceDataForWireless_Data>, I>>(base?: I): DistanceDataForWireless_Data {
    return DistanceDataForWireless_Data.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DistanceDataForWireless_Data>, I>>(object: I): DistanceDataForWireless_Data {
    const message = createBaseDistanceDataForWireless_Data();
    message.distance = object.distance ?? 0;
    message.usingTakeoffAsBackup = object.usingTakeoffAsBackup ?? false;
    message.stale = object.stale ?? false;
    return message;
  },
};

function createBaseWaypointsRequest(): WaypointsRequest {
  return { utime: 0, waypoints: {}, keysToClear: [], nonce: 0 };
}

export const WaypointsRequest = {
  encode(message: WaypointsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    Object.entries(message.waypoints).forEach(([key, value]) => {
      WaypointsRequest_WaypointsEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    writer.uint32(26).fork();
    for (const v of message.keysToClear) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.nonce !== 0) {
      writer.uint32(32).int64(message.nonce);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WaypointsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWaypointsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = WaypointsRequest_WaypointsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.waypoints[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag === 24) {
            message.keysToClear.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.keysToClear.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.nonce = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WaypointsRequest {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      waypoints: isObject(object.waypoints)
        ? Object.entries(object.waypoints).reduce<{ [key: number]: Waypoint }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = Waypoint.fromJSON(value);
          return acc;
        }, {})
        : {},
      keysToClear: globalThis.Array.isArray(object?.keysToClear)
        ? object.keysToClear.map((e: any) => globalThis.Number(e))
        : [],
      nonce: isSet(object.nonce) ? globalThis.Number(object.nonce) : 0,
    };
  },

  toJSON(message: WaypointsRequest): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.waypoints) {
      const entries = Object.entries(message.waypoints);
      if (entries.length > 0) {
        obj.waypoints = {};
        entries.forEach(([k, v]) => {
          obj.waypoints[k] = Waypoint.toJSON(v);
        });
      }
    }
    if (message.keysToClear?.length) {
      obj.keysToClear = message.keysToClear.map((e) => Math.round(e));
    }
    if (message.nonce !== 0) {
      obj.nonce = Math.round(message.nonce);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WaypointsRequest>, I>>(base?: I): WaypointsRequest {
    return WaypointsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WaypointsRequest>, I>>(object: I): WaypointsRequest {
    const message = createBaseWaypointsRequest();
    message.utime = object.utime ?? 0;
    message.waypoints = Object.entries(object.waypoints ?? {}).reduce<{ [key: number]: Waypoint }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = Waypoint.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.keysToClear = object.keysToClear?.map((e) => e) || [];
    message.nonce = object.nonce ?? 0;
    return message;
  },
};

function createBaseWaypointsRequest_WaypointsEntry(): WaypointsRequest_WaypointsEntry {
  return { key: 0, value: undefined };
}

export const WaypointsRequest_WaypointsEntry = {
  encode(message: WaypointsRequest_WaypointsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int64(message.key);
    }
    if (message.value !== undefined) {
      Waypoint.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WaypointsRequest_WaypointsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWaypointsRequest_WaypointsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Waypoint.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WaypointsRequest_WaypointsEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? Waypoint.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: WaypointsRequest_WaypointsEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = Waypoint.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WaypointsRequest_WaypointsEntry>, I>>(base?: I): WaypointsRequest_WaypointsEntry {
    return WaypointsRequest_WaypointsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WaypointsRequest_WaypointsEntry>, I>>(
    object: I,
  ): WaypointsRequest_WaypointsEntry {
    const message = createBaseWaypointsRequest_WaypointsEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? Waypoint.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseAnchorWaypoints(): AnchorWaypoints {
  return { utime: 0, launch: undefined };
}

export const AnchorWaypoints = {
  encode(message: AnchorWaypoints, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    if (message.launch !== undefined) {
      Waypoint.encode(message.launch, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnchorWaypoints {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnchorWaypoints();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.launch = Waypoint.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnchorWaypoints {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      launch: isSet(object.launch) ? Waypoint.fromJSON(object.launch) : undefined,
    };
  },

  toJSON(message: AnchorWaypoints): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.launch !== undefined) {
      obj.launch = Waypoint.toJSON(message.launch);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AnchorWaypoints>, I>>(base?: I): AnchorWaypoints {
    return AnchorWaypoints.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AnchorWaypoints>, I>>(object: I): AnchorWaypoints {
    const message = createBaseAnchorWaypoints();
    message.utime = object.utime ?? 0;
    message.launch = (object.launch !== undefined && object.launch !== null)
      ? Waypoint.fromPartial(object.launch)
      : undefined;
    return message;
  },
};

function createBaseLandmark(): Landmark {
  return { label: "", waypoint: undefined };
}

export const Landmark = {
  encode(message: Landmark, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.label !== "") {
      writer.uint32(10).string(message.label);
    }
    if (message.waypoint !== undefined) {
      Waypoint.encode(message.waypoint, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Landmark {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLandmark();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.label = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.waypoint = Waypoint.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Landmark {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : "",
      waypoint: isSet(object.waypoint) ? Waypoint.fromJSON(object.waypoint) : undefined,
    };
  },

  toJSON(message: Landmark): unknown {
    const obj: any = {};
    if (message.label !== "") {
      obj.label = message.label;
    }
    if (message.waypoint !== undefined) {
      obj.waypoint = Waypoint.toJSON(message.waypoint);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Landmark>, I>>(base?: I): Landmark {
    return Landmark.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Landmark>, I>>(object: I): Landmark {
    const message = createBaseLandmark();
    message.label = object.label ?? "";
    message.waypoint = (object.waypoint !== undefined && object.waypoint !== null)
      ? Waypoint.fromPartial(object.waypoint)
      : undefined;
    return message;
  },
};

function createBaseLandmarkList(): LandmarkList {
  return { utime: 0, landmarks: [] };
}

export const LandmarkList = {
  encode(message: LandmarkList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    for (const v of message.landmarks) {
      Landmark.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LandmarkList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLandmarkList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.landmarks.push(Landmark.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LandmarkList {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      landmarks: globalThis.Array.isArray(object?.landmarks)
        ? object.landmarks.map((e: any) => Landmark.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LandmarkList): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.landmarks?.length) {
      obj.landmarks = message.landmarks.map((e) => Landmark.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LandmarkList>, I>>(base?: I): LandmarkList {
    return LandmarkList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LandmarkList>, I>>(object: I): LandmarkList {
    const message = createBaseLandmarkList();
    message.utime = object.utime ?? 0;
    message.landmarks = object.landmarks?.map((e) => Landmark.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHeightMode(): HeightMode {
  return {};
}

export const HeightMode = {
  encode(_: HeightMode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HeightMode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHeightMode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): HeightMode {
    return {};
  },

  toJSON(_: HeightMode): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<HeightMode>, I>>(base?: I): HeightMode {
    return HeightMode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HeightMode>, I>>(_: I): HeightMode {
    const message = createBaseHeightMode();
    return message;
  },
};

function createBaseRampArgs(): RampArgs {
  return { initialElevationAngle: 0, traverseHeight: undefined, finalElevationAngle: 0 };
}

export const RampArgs = {
  encode(message: RampArgs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.initialElevationAngle !== 0) {
      writer.uint32(13).float(message.initialElevationAngle);
    }
    if (message.traverseHeight !== undefined) {
      PositionZ.encode(message.traverseHeight, writer.uint32(18).fork()).ldelim();
    }
    if (message.finalElevationAngle !== 0) {
      writer.uint32(29).float(message.finalElevationAngle);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RampArgs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRampArgs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.initialElevationAngle = reader.float();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.traverseHeight = PositionZ.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.finalElevationAngle = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RampArgs {
    return {
      initialElevationAngle: isSet(object.initialElevationAngle) ? globalThis.Number(object.initialElevationAngle) : 0,
      traverseHeight: isSet(object.traverseHeight) ? PositionZ.fromJSON(object.traverseHeight) : undefined,
      finalElevationAngle: isSet(object.finalElevationAngle) ? globalThis.Number(object.finalElevationAngle) : 0,
    };
  },

  toJSON(message: RampArgs): unknown {
    const obj: any = {};
    if (message.initialElevationAngle !== 0) {
      obj.initialElevationAngle = message.initialElevationAngle;
    }
    if (message.traverseHeight !== undefined) {
      obj.traverseHeight = PositionZ.toJSON(message.traverseHeight);
    }
    if (message.finalElevationAngle !== 0) {
      obj.finalElevationAngle = message.finalElevationAngle;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RampArgs>, I>>(base?: I): RampArgs {
    return RampArgs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RampArgs>, I>>(object: I): RampArgs {
    const message = createBaseRampArgs();
    message.initialElevationAngle = object.initialElevationAngle ?? 0;
    message.traverseHeight = (object.traverseHeight !== undefined && object.traverseHeight !== null)
      ? PositionZ.fromPartial(object.traverseHeight)
      : undefined;
    message.finalElevationAngle = object.finalElevationAngle ?? 0;
    return message;
  },
};

function createBaseTraversalMotionArgs(): TraversalMotionArgs {
  return {
    ascendSpeed: 0,
    speed: 0,
    descendSpeed: 0,
    ignoreWaypointZ: false,
    heightMode: 0,
    maxArgs: undefined,
    customArgs: undefined,
    rampArgs: undefined,
    usePathfinder: false,
    useGlobalPathfinder: false,
  };
}

export const TraversalMotionArgs = {
  encode(message: TraversalMotionArgs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ascendSpeed !== 0) {
      writer.uint32(13).float(message.ascendSpeed);
    }
    if (message.speed !== 0) {
      writer.uint32(21).float(message.speed);
    }
    if (message.descendSpeed !== 0) {
      writer.uint32(29).float(message.descendSpeed);
    }
    if (message.ignoreWaypointZ === true) {
      writer.uint32(32).bool(message.ignoreWaypointZ);
    }
    if (message.heightMode !== 0) {
      writer.uint32(40).int32(message.heightMode);
    }
    if (message.maxArgs !== undefined) {
      PositionZ.encode(message.maxArgs, writer.uint32(50).fork()).ldelim();
    }
    if (message.customArgs !== undefined) {
      PositionZ.encode(message.customArgs, writer.uint32(58).fork()).ldelim();
    }
    if (message.rampArgs !== undefined) {
      RampArgs.encode(message.rampArgs, writer.uint32(74).fork()).ldelim();
    }
    if (message.usePathfinder === true) {
      writer.uint32(64).bool(message.usePathfinder);
    }
    if (message.useGlobalPathfinder === true) {
      writer.uint32(80).bool(message.useGlobalPathfinder);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TraversalMotionArgs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTraversalMotionArgs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.ascendSpeed = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.speed = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.descendSpeed = reader.float();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.ignoreWaypointZ = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.heightMode = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.maxArgs = PositionZ.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.customArgs = PositionZ.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.rampArgs = RampArgs.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.usePathfinder = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.useGlobalPathfinder = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TraversalMotionArgs {
    return {
      ascendSpeed: isSet(object.ascendSpeed) ? globalThis.Number(object.ascendSpeed) : 0,
      speed: isSet(object.speed) ? globalThis.Number(object.speed) : 0,
      descendSpeed: isSet(object.descendSpeed) ? globalThis.Number(object.descendSpeed) : 0,
      ignoreWaypointZ: isSet(object.ignoreWaypointZ) ? globalThis.Boolean(object.ignoreWaypointZ) : false,
      heightMode: isSet(object.heightMode) ? heightMode_EnumFromJSON(object.heightMode) : 0,
      maxArgs: isSet(object.maxArgs) ? PositionZ.fromJSON(object.maxArgs) : undefined,
      customArgs: isSet(object.customArgs) ? PositionZ.fromJSON(object.customArgs) : undefined,
      rampArgs: isSet(object.rampArgs) ? RampArgs.fromJSON(object.rampArgs) : undefined,
      usePathfinder: isSet(object.usePathfinder) ? globalThis.Boolean(object.usePathfinder) : false,
      useGlobalPathfinder: isSet(object.useGlobalPathfinder) ? globalThis.Boolean(object.useGlobalPathfinder) : false,
    };
  },

  toJSON(message: TraversalMotionArgs): unknown {
    const obj: any = {};
    if (message.ascendSpeed !== 0) {
      obj.ascendSpeed = message.ascendSpeed;
    }
    if (message.speed !== 0) {
      obj.speed = message.speed;
    }
    if (message.descendSpeed !== 0) {
      obj.descendSpeed = message.descendSpeed;
    }
    if (message.ignoreWaypointZ === true) {
      obj.ignoreWaypointZ = message.ignoreWaypointZ;
    }
    if (message.heightMode !== 0) {
      obj.heightMode = heightMode_EnumToJSON(message.heightMode);
    }
    if (message.maxArgs !== undefined) {
      obj.maxArgs = PositionZ.toJSON(message.maxArgs);
    }
    if (message.customArgs !== undefined) {
      obj.customArgs = PositionZ.toJSON(message.customArgs);
    }
    if (message.rampArgs !== undefined) {
      obj.rampArgs = RampArgs.toJSON(message.rampArgs);
    }
    if (message.usePathfinder === true) {
      obj.usePathfinder = message.usePathfinder;
    }
    if (message.useGlobalPathfinder === true) {
      obj.useGlobalPathfinder = message.useGlobalPathfinder;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TraversalMotionArgs>, I>>(base?: I): TraversalMotionArgs {
    return TraversalMotionArgs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TraversalMotionArgs>, I>>(object: I): TraversalMotionArgs {
    const message = createBaseTraversalMotionArgs();
    message.ascendSpeed = object.ascendSpeed ?? 0;
    message.speed = object.speed ?? 0;
    message.descendSpeed = object.descendSpeed ?? 0;
    message.ignoreWaypointZ = object.ignoreWaypointZ ?? false;
    message.heightMode = object.heightMode ?? 0;
    message.maxArgs = (object.maxArgs !== undefined && object.maxArgs !== null)
      ? PositionZ.fromPartial(object.maxArgs)
      : undefined;
    message.customArgs = (object.customArgs !== undefined && object.customArgs !== null)
      ? PositionZ.fromPartial(object.customArgs)
      : undefined;
    message.rampArgs = (object.rampArgs !== undefined && object.rampArgs !== null)
      ? RampArgs.fromPartial(object.rampArgs)
      : undefined;
    message.usePathfinder = object.usePathfinder ?? false;
    message.useGlobalPathfinder = object.useGlobalPathfinder ?? false;
    return message;
  },
};

function createBaseHeadingMode(): HeadingMode {
  return {};
}

export const HeadingMode = {
  encode(_: HeadingMode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HeadingMode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHeadingMode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): HeadingMode {
    return {};
  },

  toJSON(_: HeadingMode): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<HeadingMode>, I>>(base?: I): HeadingMode {
    return HeadingMode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HeadingMode>, I>>(_: I): HeadingMode {
    const message = createBaseHeadingMode();
    return message;
  },
};

function createBaseGimbalPitchMode(): GimbalPitchMode {
  return {};
}

export const GimbalPitchMode = {
  encode(_: GimbalPitchMode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GimbalPitchMode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGimbalPitchMode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GimbalPitchMode {
    return {};
  },

  toJSON(_: GimbalPitchMode): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GimbalPitchMode>, I>>(base?: I): GimbalPitchMode {
    return GimbalPitchMode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GimbalPitchMode>, I>>(_: I): GimbalPitchMode {
    const message = createBaseGimbalPitchMode();
    return message;
  },
};

function createBaseLookAtMotionArgs(): LookAtMotionArgs {
  return {
    ignoreTargetHeading: false,
    headingMode: 0,
    customHeading: undefined,
    ignoreTargetGimbalPitch: false,
    gimbalPitchMode: 0,
    customGimbalPitch: undefined,
    lookatPoint: undefined,
    lookatHeight: undefined,
    targetTLookat: undefined,
  };
}

export const LookAtMotionArgs = {
  encode(message: LookAtMotionArgs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ignoreTargetHeading === true) {
      writer.uint32(8).bool(message.ignoreTargetHeading);
    }
    if (message.headingMode !== 0) {
      writer.uint32(16).int32(message.headingMode);
    }
    if (message.customHeading !== undefined) {
      Heading.encode(message.customHeading, writer.uint32(42).fork()).ldelim();
    }
    if (message.ignoreTargetGimbalPitch === true) {
      writer.uint32(24).bool(message.ignoreTargetGimbalPitch);
    }
    if (message.gimbalPitchMode !== 0) {
      writer.uint32(32).int32(message.gimbalPitchMode);
    }
    if (message.customGimbalPitch !== undefined) {
      GimbalPitch.encode(message.customGimbalPitch, writer.uint32(50).fork()).ldelim();
    }
    if (message.lookatPoint !== undefined) {
      PositionXY.encode(message.lookatPoint, writer.uint32(58).fork()).ldelim();
    }
    if (message.lookatHeight !== undefined) {
      PositionZ.encode(message.lookatHeight, writer.uint32(66).fork()).ldelim();
    }
    if (message.targetTLookat !== undefined) {
      Vector3dPb.encode(message.targetTLookat, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookAtMotionArgs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookAtMotionArgs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ignoreTargetHeading = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.headingMode = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.customHeading = Heading.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.ignoreTargetGimbalPitch = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.gimbalPitchMode = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.customGimbalPitch = GimbalPitch.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lookatPoint = PositionXY.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.lookatHeight = PositionZ.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.targetTLookat = Vector3dPb.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookAtMotionArgs {
    return {
      ignoreTargetHeading: isSet(object.ignoreTargetHeading) ? globalThis.Boolean(object.ignoreTargetHeading) : false,
      headingMode: isSet(object.headingMode) ? headingMode_EnumFromJSON(object.headingMode) : 0,
      customHeading: isSet(object.customHeading) ? Heading.fromJSON(object.customHeading) : undefined,
      ignoreTargetGimbalPitch: isSet(object.ignoreTargetGimbalPitch)
        ? globalThis.Boolean(object.ignoreTargetGimbalPitch)
        : false,
      gimbalPitchMode: isSet(object.gimbalPitchMode) ? gimbalPitchMode_EnumFromJSON(object.gimbalPitchMode) : 0,
      customGimbalPitch: isSet(object.customGimbalPitch) ? GimbalPitch.fromJSON(object.customGimbalPitch) : undefined,
      lookatPoint: isSet(object.lookatPoint) ? PositionXY.fromJSON(object.lookatPoint) : undefined,
      lookatHeight: isSet(object.lookatHeight) ? PositionZ.fromJSON(object.lookatHeight) : undefined,
      targetTLookat: isSet(object.targetTLookat) ? Vector3dPb.fromJSON(object.targetTLookat) : undefined,
    };
  },

  toJSON(message: LookAtMotionArgs): unknown {
    const obj: any = {};
    if (message.ignoreTargetHeading === true) {
      obj.ignoreTargetHeading = message.ignoreTargetHeading;
    }
    if (message.headingMode !== 0) {
      obj.headingMode = headingMode_EnumToJSON(message.headingMode);
    }
    if (message.customHeading !== undefined) {
      obj.customHeading = Heading.toJSON(message.customHeading);
    }
    if (message.ignoreTargetGimbalPitch === true) {
      obj.ignoreTargetGimbalPitch = message.ignoreTargetGimbalPitch;
    }
    if (message.gimbalPitchMode !== 0) {
      obj.gimbalPitchMode = gimbalPitchMode_EnumToJSON(message.gimbalPitchMode);
    }
    if (message.customGimbalPitch !== undefined) {
      obj.customGimbalPitch = GimbalPitch.toJSON(message.customGimbalPitch);
    }
    if (message.lookatPoint !== undefined) {
      obj.lookatPoint = PositionXY.toJSON(message.lookatPoint);
    }
    if (message.lookatHeight !== undefined) {
      obj.lookatHeight = PositionZ.toJSON(message.lookatHeight);
    }
    if (message.targetTLookat !== undefined) {
      obj.targetTLookat = Vector3dPb.toJSON(message.targetTLookat);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookAtMotionArgs>, I>>(base?: I): LookAtMotionArgs {
    return LookAtMotionArgs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookAtMotionArgs>, I>>(object: I): LookAtMotionArgs {
    const message = createBaseLookAtMotionArgs();
    message.ignoreTargetHeading = object.ignoreTargetHeading ?? false;
    message.headingMode = object.headingMode ?? 0;
    message.customHeading = (object.customHeading !== undefined && object.customHeading !== null)
      ? Heading.fromPartial(object.customHeading)
      : undefined;
    message.ignoreTargetGimbalPitch = object.ignoreTargetGimbalPitch ?? false;
    message.gimbalPitchMode = object.gimbalPitchMode ?? 0;
    message.customGimbalPitch = (object.customGimbalPitch !== undefined && object.customGimbalPitch !== null)
      ? GimbalPitch.fromPartial(object.customGimbalPitch)
      : undefined;
    message.lookatPoint = (object.lookatPoint !== undefined && object.lookatPoint !== null)
      ? PositionXY.fromPartial(object.lookatPoint)
      : undefined;
    message.lookatHeight = (object.lookatHeight !== undefined && object.lookatHeight !== null)
      ? PositionZ.fromPartial(object.lookatHeight)
      : undefined;
    message.targetTLookat = (object.targetTLookat !== undefined && object.targetTLookat !== null)
      ? Vector3dPb.fromPartial(object.targetTLookat)
      : undefined;
    return message;
  },
};

function createBaseFreeLookMode(): FreeLookMode {
  return {};
}

export const FreeLookMode = {
  encode(_: FreeLookMode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FreeLookMode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFreeLookMode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FreeLookMode {
    return {};
  },

  toJSON(_: FreeLookMode): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FreeLookMode>, I>>(base?: I): FreeLookMode {
    return FreeLookMode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FreeLookMode>, I>>(_: I): FreeLookMode {
    const message = createBaseFreeLookMode();
    return message;
  },
};

function createBaseAllowedEdges(): AllowedEdges {
  return { vehiclePaths: false, loadedPaths: false };
}

export const AllowedEdges = {
  encode(message: AllowedEdges, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vehiclePaths === true) {
      writer.uint32(8).bool(message.vehiclePaths);
    }
    if (message.loadedPaths === true) {
      writer.uint32(16).bool(message.loadedPaths);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AllowedEdges {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAllowedEdges();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.vehiclePaths = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.loadedPaths = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AllowedEdges {
    return {
      vehiclePaths: isSet(object.vehiclePaths) ? globalThis.Boolean(object.vehiclePaths) : false,
      loadedPaths: isSet(object.loadedPaths) ? globalThis.Boolean(object.loadedPaths) : false,
    };
  },

  toJSON(message: AllowedEdges): unknown {
    const obj: any = {};
    if (message.vehiclePaths === true) {
      obj.vehiclePaths = message.vehiclePaths;
    }
    if (message.loadedPaths === true) {
      obj.loadedPaths = message.loadedPaths;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AllowedEdges>, I>>(base?: I): AllowedEdges {
    return AllowedEdges.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AllowedEdges>, I>>(object: I): AllowedEdges {
    const message = createBaseAllowedEdges();
    message.vehiclePaths = object.vehiclePaths ?? false;
    message.loadedPaths = object.loadedPaths ?? false;
    return message;
  },
};

function createBaseGraphMotionArgs(): GraphMotionArgs {
  return { speed: 0, allowedEdges: undefined, useSpline: false, slowdownNearTarget: false };
}

export const GraphMotionArgs = {
  encode(message: GraphMotionArgs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.speed !== 0) {
      writer.uint32(13).float(message.speed);
    }
    if (message.allowedEdges !== undefined) {
      AllowedEdges.encode(message.allowedEdges, writer.uint32(18).fork()).ldelim();
    }
    if (message.useSpline === true) {
      writer.uint32(24).bool(message.useSpline);
    }
    if (message.slowdownNearTarget === true) {
      writer.uint32(32).bool(message.slowdownNearTarget);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GraphMotionArgs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGraphMotionArgs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.speed = reader.float();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.allowedEdges = AllowedEdges.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.useSpline = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.slowdownNearTarget = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GraphMotionArgs {
    return {
      speed: isSet(object.speed) ? globalThis.Number(object.speed) : 0,
      allowedEdges: isSet(object.allowedEdges) ? AllowedEdges.fromJSON(object.allowedEdges) : undefined,
      useSpline: isSet(object.useSpline) ? globalThis.Boolean(object.useSpline) : false,
      slowdownNearTarget: isSet(object.slowdownNearTarget) ? globalThis.Boolean(object.slowdownNearTarget) : false,
    };
  },

  toJSON(message: GraphMotionArgs): unknown {
    const obj: any = {};
    if (message.speed !== 0) {
      obj.speed = message.speed;
    }
    if (message.allowedEdges !== undefined) {
      obj.allowedEdges = AllowedEdges.toJSON(message.allowedEdges);
    }
    if (message.useSpline === true) {
      obj.useSpline = message.useSpline;
    }
    if (message.slowdownNearTarget === true) {
      obj.slowdownNearTarget = message.slowdownNearTarget;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GraphMotionArgs>, I>>(base?: I): GraphMotionArgs {
    return GraphMotionArgs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GraphMotionArgs>, I>>(object: I): GraphMotionArgs {
    const message = createBaseGraphMotionArgs();
    message.speed = object.speed ?? 0;
    message.allowedEdges = (object.allowedEdges !== undefined && object.allowedEdges !== null)
      ? AllowedEdges.fromPartial(object.allowedEdges)
      : undefined;
    message.useSpline = object.useSpline ?? false;
    message.slowdownNearTarget = object.slowdownNearTarget ?? false;
    return message;
  },
};

function createBaseMotionArgs(): MotionArgs {
  return { traversalArgs: undefined, lookAtArgs: undefined, graphArgs: undefined, terminationArgs: undefined };
}

export const MotionArgs = {
  encode(message: MotionArgs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.traversalArgs !== undefined) {
      TraversalMotionArgs.encode(message.traversalArgs, writer.uint32(10).fork()).ldelim();
    }
    if (message.lookAtArgs !== undefined) {
      LookAtMotionArgs.encode(message.lookAtArgs, writer.uint32(18).fork()).ldelim();
    }
    if (message.graphArgs !== undefined) {
      GraphMotionArgs.encode(message.graphArgs, writer.uint32(26).fork()).ldelim();
    }
    if (message.terminationArgs !== undefined) {
      WaypointTerminationArgs.encode(message.terminationArgs, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MotionArgs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMotionArgs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.traversalArgs = TraversalMotionArgs.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lookAtArgs = LookAtMotionArgs.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.graphArgs = GraphMotionArgs.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.terminationArgs = WaypointTerminationArgs.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MotionArgs {
    return {
      traversalArgs: isSet(object.traversalArgs) ? TraversalMotionArgs.fromJSON(object.traversalArgs) : undefined,
      lookAtArgs: isSet(object.lookAtArgs) ? LookAtMotionArgs.fromJSON(object.lookAtArgs) : undefined,
      graphArgs: isSet(object.graphArgs) ? GraphMotionArgs.fromJSON(object.graphArgs) : undefined,
      terminationArgs: isSet(object.terminationArgs)
        ? WaypointTerminationArgs.fromJSON(object.terminationArgs)
        : undefined,
    };
  },

  toJSON(message: MotionArgs): unknown {
    const obj: any = {};
    if (message.traversalArgs !== undefined) {
      obj.traversalArgs = TraversalMotionArgs.toJSON(message.traversalArgs);
    }
    if (message.lookAtArgs !== undefined) {
      obj.lookAtArgs = LookAtMotionArgs.toJSON(message.lookAtArgs);
    }
    if (message.graphArgs !== undefined) {
      obj.graphArgs = GraphMotionArgs.toJSON(message.graphArgs);
    }
    if (message.terminationArgs !== undefined) {
      obj.terminationArgs = WaypointTerminationArgs.toJSON(message.terminationArgs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MotionArgs>, I>>(base?: I): MotionArgs {
    return MotionArgs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MotionArgs>, I>>(object: I): MotionArgs {
    const message = createBaseMotionArgs();
    message.traversalArgs = (object.traversalArgs !== undefined && object.traversalArgs !== null)
      ? TraversalMotionArgs.fromPartial(object.traversalArgs)
      : undefined;
    message.lookAtArgs = (object.lookAtArgs !== undefined && object.lookAtArgs !== null)
      ? LookAtMotionArgs.fromPartial(object.lookAtArgs)
      : undefined;
    message.graphArgs = (object.graphArgs !== undefined && object.graphArgs !== null)
      ? GraphMotionArgs.fromPartial(object.graphArgs)
      : undefined;
    message.terminationArgs = (object.terminationArgs !== undefined && object.terminationArgs !== null)
      ? WaypointTerminationArgs.fromPartial(object.terminationArgs)
      : undefined;
    return message;
  },
};

function createBaseWaypointTerminationArgs(): WaypointTerminationArgs {
  return { xyPositionThreshold: 0, zPositionThreshold: 0 };
}

export const WaypointTerminationArgs = {
  encode(message: WaypointTerminationArgs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.xyPositionThreshold !== 0) {
      writer.uint32(13).float(message.xyPositionThreshold);
    }
    if (message.zPositionThreshold !== 0) {
      writer.uint32(21).float(message.zPositionThreshold);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WaypointTerminationArgs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWaypointTerminationArgs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.xyPositionThreshold = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.zPositionThreshold = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WaypointTerminationArgs {
    return {
      xyPositionThreshold: isSet(object.xyPositionThreshold) ? globalThis.Number(object.xyPositionThreshold) : 0,
      zPositionThreshold: isSet(object.zPositionThreshold) ? globalThis.Number(object.zPositionThreshold) : 0,
    };
  },

  toJSON(message: WaypointTerminationArgs): unknown {
    const obj: any = {};
    if (message.xyPositionThreshold !== 0) {
      obj.xyPositionThreshold = message.xyPositionThreshold;
    }
    if (message.zPositionThreshold !== 0) {
      obj.zPositionThreshold = message.zPositionThreshold;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WaypointTerminationArgs>, I>>(base?: I): WaypointTerminationArgs {
    return WaypointTerminationArgs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WaypointTerminationArgs>, I>>(object: I): WaypointTerminationArgs {
    const message = createBaseWaypointTerminationArgs();
    message.xyPositionThreshold = object.xyPositionThreshold ?? 0;
    message.zPositionThreshold = object.zPositionThreshold ?? 0;
    return message;
  },
};

function createBaseWaypointKey(): WaypointKey {
  return { value: 0 };
}

export const WaypointKey = {
  encode(message: WaypointKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(8).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WaypointKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWaypointKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WaypointKey {
    return { value: isSet(object.value) ? globalThis.Number(object.value) : 0 };
  },

  toJSON(message: WaypointKey): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WaypointKey>, I>>(base?: I): WaypointKey {
    return WaypointKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WaypointKey>, I>>(object: I): WaypointKey {
    const message = createBaseWaypointKey();
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseTargetWaypoint(): TargetWaypoint {
  return { key: undefined, waypoint: undefined };
}

export const TargetWaypoint = {
  encode(message: TargetWaypoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== undefined) {
      WaypointKey.encode(message.key, writer.uint32(10).fork()).ldelim();
    }
    if (message.waypoint !== undefined) {
      Waypoint.encode(message.waypoint, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TargetWaypoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTargetWaypoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = WaypointKey.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.waypoint = Waypoint.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TargetWaypoint {
    return {
      key: isSet(object.key) ? WaypointKey.fromJSON(object.key) : undefined,
      waypoint: isSet(object.waypoint) ? Waypoint.fromJSON(object.waypoint) : undefined,
    };
  },

  toJSON(message: TargetWaypoint): unknown {
    const obj: any = {};
    if (message.key !== undefined) {
      obj.key = WaypointKey.toJSON(message.key);
    }
    if (message.waypoint !== undefined) {
      obj.waypoint = Waypoint.toJSON(message.waypoint);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TargetWaypoint>, I>>(base?: I): TargetWaypoint {
    return TargetWaypoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TargetWaypoint>, I>>(object: I): TargetWaypoint {
    const message = createBaseTargetWaypoint();
    message.key = (object.key !== undefined && object.key !== null) ? WaypointKey.fromPartial(object.key) : undefined;
    message.waypoint = (object.waypoint !== undefined && object.waypoint !== null)
      ? Waypoint.fromPartial(object.waypoint)
      : undefined;
    return message;
  },
};

function createBaseGoToWaypointRequest(): GoToWaypointRequest {
  return {
    utime: 0,
    nonce: 0,
    startFlying: false,
    target: undefined,
    motionArgs: undefined,
    source: 0,
    landOnComplete: false,
    priority: 0,
  };
}

export const GoToWaypointRequest = {
  encode(message: GoToWaypointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    if (message.nonce !== 0) {
      writer.uint32(64).int64(message.nonce);
    }
    if (message.startFlying === true) {
      writer.uint32(16).bool(message.startFlying);
    }
    if (message.target !== undefined) {
      TargetWaypoint.encode(message.target, writer.uint32(26).fork()).ldelim();
    }
    if (message.motionArgs !== undefined) {
      MotionArgs.encode(message.motionArgs, writer.uint32(34).fork()).ldelim();
    }
    if (message.source !== 0) {
      writer.uint32(40).int32(message.source);
    }
    if (message.landOnComplete === true) {
      writer.uint32(48).bool(message.landOnComplete);
    }
    if (message.priority !== 0) {
      writer.uint32(56).uint32(message.priority);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GoToWaypointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGoToWaypointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.nonce = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.startFlying = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.target = TargetWaypoint.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.motionArgs = MotionArgs.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.landOnComplete = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.priority = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GoToWaypointRequest {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      nonce: isSet(object.nonce) ? globalThis.Number(object.nonce) : 0,
      startFlying: isSet(object.startFlying) ? globalThis.Boolean(object.startFlying) : false,
      target: isSet(object.target) ? TargetWaypoint.fromJSON(object.target) : undefined,
      motionArgs: isSet(object.motionArgs) ? MotionArgs.fromJSON(object.motionArgs) : undefined,
      source: isSet(object.source) ? goToWaypointSource_EnumFromJSON(object.source) : 0,
      landOnComplete: isSet(object.landOnComplete) ? globalThis.Boolean(object.landOnComplete) : false,
      priority: isSet(object.priority) ? globalThis.Number(object.priority) : 0,
    };
  },

  toJSON(message: GoToWaypointRequest): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.nonce !== 0) {
      obj.nonce = Math.round(message.nonce);
    }
    if (message.startFlying === true) {
      obj.startFlying = message.startFlying;
    }
    if (message.target !== undefined) {
      obj.target = TargetWaypoint.toJSON(message.target);
    }
    if (message.motionArgs !== undefined) {
      obj.motionArgs = MotionArgs.toJSON(message.motionArgs);
    }
    if (message.source !== 0) {
      obj.source = goToWaypointSource_EnumToJSON(message.source);
    }
    if (message.landOnComplete === true) {
      obj.landOnComplete = message.landOnComplete;
    }
    if (message.priority !== 0) {
      obj.priority = Math.round(message.priority);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GoToWaypointRequest>, I>>(base?: I): GoToWaypointRequest {
    return GoToWaypointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GoToWaypointRequest>, I>>(object: I): GoToWaypointRequest {
    const message = createBaseGoToWaypointRequest();
    message.utime = object.utime ?? 0;
    message.nonce = object.nonce ?? 0;
    message.startFlying = object.startFlying ?? false;
    message.target = (object.target !== undefined && object.target !== null)
      ? TargetWaypoint.fromPartial(object.target)
      : undefined;
    message.motionArgs = (object.motionArgs !== undefined && object.motionArgs !== null)
      ? MotionArgs.fromPartial(object.motionArgs)
      : undefined;
    message.source = object.source ?? 0;
    message.landOnComplete = object.landOnComplete ?? false;
    message.priority = object.priority ?? 0;
    return message;
  },
};

function createBaseGoToWaypointState(): GoToWaypointState {
  return {};
}

export const GoToWaypointState = {
  encode(_: GoToWaypointState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GoToWaypointState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGoToWaypointState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GoToWaypointState {
    return {};
  },

  toJSON(_: GoToWaypointState): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GoToWaypointState>, I>>(base?: I): GoToWaypointState {
    return GoToWaypointState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GoToWaypointState>, I>>(_: I): GoToWaypointState {
    const message = createBaseGoToWaypointState();
    return message;
  },
};

function createBaseDistanceSegments(): DistanceSegments {
  return { upDistance: 0, traverseDistance: 0, downDistance: 0 };
}

export const DistanceSegments = {
  encode(message: DistanceSegments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.upDistance !== 0) {
      writer.uint32(9).double(message.upDistance);
    }
    if (message.traverseDistance !== 0) {
      writer.uint32(17).double(message.traverseDistance);
    }
    if (message.downDistance !== 0) {
      writer.uint32(25).double(message.downDistance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DistanceSegments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDistanceSegments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.upDistance = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.traverseDistance = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.downDistance = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DistanceSegments {
    return {
      upDistance: isSet(object.upDistance) ? globalThis.Number(object.upDistance) : 0,
      traverseDistance: isSet(object.traverseDistance) ? globalThis.Number(object.traverseDistance) : 0,
      downDistance: isSet(object.downDistance) ? globalThis.Number(object.downDistance) : 0,
    };
  },

  toJSON(message: DistanceSegments): unknown {
    const obj: any = {};
    if (message.upDistance !== 0) {
      obj.upDistance = message.upDistance;
    }
    if (message.traverseDistance !== 0) {
      obj.traverseDistance = message.traverseDistance;
    }
    if (message.downDistance !== 0) {
      obj.downDistance = message.downDistance;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DistanceSegments>, I>>(base?: I): DistanceSegments {
    return DistanceSegments.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DistanceSegments>, I>>(object: I): DistanceSegments {
    const message = createBaseDistanceSegments();
    message.upDistance = object.upDistance ?? 0;
    message.traverseDistance = object.traverseDistance ?? 0;
    message.downDistance = object.downDistance ?? 0;
    return message;
  },
};

function createBaseDisplacementSegments(): DisplacementSegments {
  return { upDisplacement: undefined, traverseDisplacements: [], downDisplacement: undefined };
}

export const DisplacementSegments = {
  encode(message: DisplacementSegments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.upDisplacement !== undefined) {
      Vector3dPb.encode(message.upDisplacement, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.traverseDisplacements) {
      Vector3dPb.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.downDisplacement !== undefined) {
      Vector3dPb.encode(message.downDisplacement, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DisplacementSegments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisplacementSegments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.upDisplacement = Vector3dPb.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.traverseDisplacements.push(Vector3dPb.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.downDisplacement = Vector3dPb.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DisplacementSegments {
    return {
      upDisplacement: isSet(object.upDisplacement) ? Vector3dPb.fromJSON(object.upDisplacement) : undefined,
      traverseDisplacements: globalThis.Array.isArray(object?.traverseDisplacements)
        ? object.traverseDisplacements.map((e: any) => Vector3dPb.fromJSON(e))
        : [],
      downDisplacement: isSet(object.downDisplacement) ? Vector3dPb.fromJSON(object.downDisplacement) : undefined,
    };
  },

  toJSON(message: DisplacementSegments): unknown {
    const obj: any = {};
    if (message.upDisplacement !== undefined) {
      obj.upDisplacement = Vector3dPb.toJSON(message.upDisplacement);
    }
    if (message.traverseDisplacements?.length) {
      obj.traverseDisplacements = message.traverseDisplacements.map((e) => Vector3dPb.toJSON(e));
    }
    if (message.downDisplacement !== undefined) {
      obj.downDisplacement = Vector3dPb.toJSON(message.downDisplacement);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DisplacementSegments>, I>>(base?: I): DisplacementSegments {
    return DisplacementSegments.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DisplacementSegments>, I>>(object: I): DisplacementSegments {
    const message = createBaseDisplacementSegments();
    message.upDisplacement = (object.upDisplacement !== undefined && object.upDisplacement !== null)
      ? Vector3dPb.fromPartial(object.upDisplacement)
      : undefined;
    message.traverseDisplacements = object.traverseDisplacements?.map((e) => Vector3dPb.fromPartial(e)) || [];
    message.downDisplacement = (object.downDisplacement !== undefined && object.downDisplacement !== null)
      ? Vector3dPb.fromPartial(object.downDisplacement)
      : undefined;
    return message;
  },
};

function createBaseSegmentEstimates(): SegmentEstimates {
  return { traversalSegments: [] };
}

export const SegmentEstimates = {
  encode(message: SegmentEstimates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.traversalSegments) {
      SegmentEstimate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SegmentEstimates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSegmentEstimates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.traversalSegments.push(SegmentEstimate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SegmentEstimates {
    return {
      traversalSegments: globalThis.Array.isArray(object?.traversalSegments)
        ? object.traversalSegments.map((e: any) => SegmentEstimate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SegmentEstimates): unknown {
    const obj: any = {};
    if (message.traversalSegments?.length) {
      obj.traversalSegments = message.traversalSegments.map((e) => SegmentEstimate.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SegmentEstimates>, I>>(base?: I): SegmentEstimates {
    return SegmentEstimates.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SegmentEstimates>, I>>(object: I): SegmentEstimates {
    const message = createBaseSegmentEstimates();
    message.traversalSegments = object.traversalSegments?.map((e) => SegmentEstimate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSegmentEstimate(): SegmentEstimate {
  return { distance: 0, time: 0, groundSpeed: 0, airSpeed: 0, energy: 0 };
}

export const SegmentEstimate = {
  encode(message: SegmentEstimate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.distance !== 0) {
      writer.uint32(9).double(message.distance);
    }
    if (message.time !== 0) {
      writer.uint32(17).double(message.time);
    }
    if (message.groundSpeed !== 0) {
      writer.uint32(25).double(message.groundSpeed);
    }
    if (message.airSpeed !== 0) {
      writer.uint32(33).double(message.airSpeed);
    }
    if (message.energy !== 0) {
      writer.uint32(41).double(message.energy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SegmentEstimate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSegmentEstimate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.distance = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.time = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.groundSpeed = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.airSpeed = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.energy = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SegmentEstimate {
    return {
      distance: isSet(object.distance) ? globalThis.Number(object.distance) : 0,
      time: isSet(object.time) ? globalThis.Number(object.time) : 0,
      groundSpeed: isSet(object.groundSpeed) ? globalThis.Number(object.groundSpeed) : 0,
      airSpeed: isSet(object.airSpeed) ? globalThis.Number(object.airSpeed) : 0,
      energy: isSet(object.energy) ? globalThis.Number(object.energy) : 0,
    };
  },

  toJSON(message: SegmentEstimate): unknown {
    const obj: any = {};
    if (message.distance !== 0) {
      obj.distance = message.distance;
    }
    if (message.time !== 0) {
      obj.time = message.time;
    }
    if (message.groundSpeed !== 0) {
      obj.groundSpeed = message.groundSpeed;
    }
    if (message.airSpeed !== 0) {
      obj.airSpeed = message.airSpeed;
    }
    if (message.energy !== 0) {
      obj.energy = message.energy;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SegmentEstimate>, I>>(base?: I): SegmentEstimate {
    return SegmentEstimate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SegmentEstimate>, I>>(object: I): SegmentEstimate {
    const message = createBaseSegmentEstimate();
    message.distance = object.distance ?? 0;
    message.time = object.time ?? 0;
    message.groundSpeed = object.groundSpeed ?? 0;
    message.airSpeed = object.airSpeed ?? 0;
    message.energy = object.energy ?? 0;
    return message;
  },
};

function createBaseHasIntermediateGoals(): HasIntermediateGoals {
  return { hasUpGoal: false, hasTraverseGoal: false, hasDownGoal: false };
}

export const HasIntermediateGoals = {
  encode(message: HasIntermediateGoals, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hasUpGoal === true) {
      writer.uint32(8).bool(message.hasUpGoal);
    }
    if (message.hasTraverseGoal === true) {
      writer.uint32(16).bool(message.hasTraverseGoal);
    }
    if (message.hasDownGoal === true) {
      writer.uint32(24).bool(message.hasDownGoal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HasIntermediateGoals {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHasIntermediateGoals();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.hasUpGoal = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasTraverseGoal = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasDownGoal = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HasIntermediateGoals {
    return {
      hasUpGoal: isSet(object.hasUpGoal) ? globalThis.Boolean(object.hasUpGoal) : false,
      hasTraverseGoal: isSet(object.hasTraverseGoal) ? globalThis.Boolean(object.hasTraverseGoal) : false,
      hasDownGoal: isSet(object.hasDownGoal) ? globalThis.Boolean(object.hasDownGoal) : false,
    };
  },

  toJSON(message: HasIntermediateGoals): unknown {
    const obj: any = {};
    if (message.hasUpGoal === true) {
      obj.hasUpGoal = message.hasUpGoal;
    }
    if (message.hasTraverseGoal === true) {
      obj.hasTraverseGoal = message.hasTraverseGoal;
    }
    if (message.hasDownGoal === true) {
      obj.hasDownGoal = message.hasDownGoal;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HasIntermediateGoals>, I>>(base?: I): HasIntermediateGoals {
    return HasIntermediateGoals.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HasIntermediateGoals>, I>>(object: I): HasIntermediateGoals {
    const message = createBaseHasIntermediateGoals();
    message.hasUpGoal = object.hasUpGoal ?? false;
    message.hasTraverseGoal = object.hasTraverseGoal ?? false;
    message.hasDownGoal = object.hasDownGoal ?? false;
    return message;
  },
};

function createBaseUpAndOverProgressUpperBounds(): UpAndOverProgressUpperBounds {
  return { upProgress: 0, traverseProgress: 0, downProgress: 0 };
}

export const UpAndOverProgressUpperBounds = {
  encode(message: UpAndOverProgressUpperBounds, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.upProgress !== 0) {
      writer.uint32(9).double(message.upProgress);
    }
    if (message.traverseProgress !== 0) {
      writer.uint32(17).double(message.traverseProgress);
    }
    if (message.downProgress !== 0) {
      writer.uint32(25).double(message.downProgress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpAndOverProgressUpperBounds {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpAndOverProgressUpperBounds();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.upProgress = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.traverseProgress = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.downProgress = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpAndOverProgressUpperBounds {
    return {
      upProgress: isSet(object.upProgress) ? globalThis.Number(object.upProgress) : 0,
      traverseProgress: isSet(object.traverseProgress) ? globalThis.Number(object.traverseProgress) : 0,
      downProgress: isSet(object.downProgress) ? globalThis.Number(object.downProgress) : 0,
    };
  },

  toJSON(message: UpAndOverProgressUpperBounds): unknown {
    const obj: any = {};
    if (message.upProgress !== 0) {
      obj.upProgress = message.upProgress;
    }
    if (message.traverseProgress !== 0) {
      obj.traverseProgress = message.traverseProgress;
    }
    if (message.downProgress !== 0) {
      obj.downProgress = message.downProgress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpAndOverProgressUpperBounds>, I>>(base?: I): UpAndOverProgressUpperBounds {
    return UpAndOverProgressUpperBounds.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpAndOverProgressUpperBounds>, I>>(object: I): UpAndOverProgressUpperBounds {
    const message = createBaseUpAndOverProgressUpperBounds();
    message.upProgress = object.upProgress ?? 0;
    message.traverseProgress = object.traverseProgress ?? 0;
    message.downProgress = object.downProgress ?? 0;
    return message;
  },
};

function createBaseTraversalStatus(): TraversalStatus {
  return {
    upAndOverState: 0,
    hasIntermediateGoals: undefined,
    upAndOverProgressUpperBounds: undefined,
    distanceSegments: undefined,
  };
}

export const TraversalStatus = {
  encode(message: TraversalStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.upAndOverState !== 0) {
      writer.uint32(8).int32(message.upAndOverState);
    }
    if (message.hasIntermediateGoals !== undefined) {
      HasIntermediateGoals.encode(message.hasIntermediateGoals, writer.uint32(18).fork()).ldelim();
    }
    if (message.upAndOverProgressUpperBounds !== undefined) {
      UpAndOverProgressUpperBounds.encode(message.upAndOverProgressUpperBounds, writer.uint32(26).fork()).ldelim();
    }
    if (message.distanceSegments !== undefined) {
      DistanceSegments.encode(message.distanceSegments, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TraversalStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTraversalStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.upAndOverState = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hasIntermediateGoals = HasIntermediateGoals.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.upAndOverProgressUpperBounds = UpAndOverProgressUpperBounds.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.distanceSegments = DistanceSegments.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TraversalStatus {
    return {
      upAndOverState: isSet(object.upAndOverState) ? upAndOverState_EnumFromJSON(object.upAndOverState) : 0,
      hasIntermediateGoals: isSet(object.hasIntermediateGoals)
        ? HasIntermediateGoals.fromJSON(object.hasIntermediateGoals)
        : undefined,
      upAndOverProgressUpperBounds: isSet(object.upAndOverProgressUpperBounds)
        ? UpAndOverProgressUpperBounds.fromJSON(object.upAndOverProgressUpperBounds)
        : undefined,
      distanceSegments: isSet(object.distanceSegments) ? DistanceSegments.fromJSON(object.distanceSegments) : undefined,
    };
  },

  toJSON(message: TraversalStatus): unknown {
    const obj: any = {};
    if (message.upAndOverState !== 0) {
      obj.upAndOverState = upAndOverState_EnumToJSON(message.upAndOverState);
    }
    if (message.hasIntermediateGoals !== undefined) {
      obj.hasIntermediateGoals = HasIntermediateGoals.toJSON(message.hasIntermediateGoals);
    }
    if (message.upAndOverProgressUpperBounds !== undefined) {
      obj.upAndOverProgressUpperBounds = UpAndOverProgressUpperBounds.toJSON(message.upAndOverProgressUpperBounds);
    }
    if (message.distanceSegments !== undefined) {
      obj.distanceSegments = DistanceSegments.toJSON(message.distanceSegments);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TraversalStatus>, I>>(base?: I): TraversalStatus {
    return TraversalStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TraversalStatus>, I>>(object: I): TraversalStatus {
    const message = createBaseTraversalStatus();
    message.upAndOverState = object.upAndOverState ?? 0;
    message.hasIntermediateGoals = (object.hasIntermediateGoals !== undefined && object.hasIntermediateGoals !== null)
      ? HasIntermediateGoals.fromPartial(object.hasIntermediateGoals)
      : undefined;
    message.upAndOverProgressUpperBounds =
      (object.upAndOverProgressUpperBounds !== undefined && object.upAndOverProgressUpperBounds !== null)
        ? UpAndOverProgressUpperBounds.fromPartial(object.upAndOverProgressUpperBounds)
        : undefined;
    message.distanceSegments = (object.distanceSegments !== undefined && object.distanceSegments !== null)
      ? DistanceSegments.fromPartial(object.distanceSegments)
      : undefined;
    return message;
  },
};

function createBaseGraphStatus(): GraphStatus {
  return {};
}

export const GraphStatus = {
  encode(_: GraphStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GraphStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGraphStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GraphStatus {
    return {};
  },

  toJSON(_: GraphStatus): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GraphStatus>, I>>(base?: I): GraphStatus {
    return GraphStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GraphStatus>, I>>(_: I): GraphStatus {
    const message = createBaseGraphStatus();
    return message;
  },
};

function createBaseGoToWaypointStatus(): GoToWaypointStatus {
  return {
    utime: 0,
    nonce: 0,
    acceptedTargetNonce: 0,
    distanceTraveled: 0,
    totalDistance: 0,
    target: undefined,
    state: 0,
    source: 0,
    triggeringFault: 0,
    failingFault: 0,
    motionArgs: undefined,
    traversalStatus: undefined,
    graphStatus: undefined,
    adjustedTargetWaypointInNav: undefined,
    targetInGps: undefined,
    landOnComplete: false,
    criticalRtxType: 0,
    intermediateWaypoints: [],
    intermediateWaypointsDoneComputing: false,
    timeRemaining: 0,
    isFlyingToWaypoint: false,
    doneFlyingToWaypoint: false,
    readyForWaypointCommands: false,
    isReturning: false,
    taskUuid: undefined,
  };
}

export const GoToWaypointStatus = {
  encode(message: GoToWaypointStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    if (message.nonce !== 0) {
      writer.uint32(16).int64(message.nonce);
    }
    if (message.acceptedTargetNonce !== 0) {
      writer.uint32(88).int64(message.acceptedTargetNonce);
    }
    if (message.distanceTraveled !== 0) {
      writer.uint32(29).float(message.distanceTraveled);
    }
    if (message.totalDistance !== 0) {
      writer.uint32(37).float(message.totalDistance);
    }
    if (message.target !== undefined) {
      TargetWaypoint.encode(message.target, writer.uint32(42).fork()).ldelim();
    }
    if (message.state !== 0) {
      writer.uint32(48).int32(message.state);
    }
    if (message.source !== 0) {
      writer.uint32(56).int32(message.source);
    }
    if (message.triggeringFault !== 0) {
      writer.uint32(104).int32(message.triggeringFault);
    }
    if (message.failingFault !== 0) {
      writer.uint32(128).int32(message.failingFault);
    }
    if (message.motionArgs !== undefined) {
      MotionArgs.encode(message.motionArgs, writer.uint32(66).fork()).ldelim();
    }
    if (message.traversalStatus !== undefined) {
      TraversalStatus.encode(message.traversalStatus, writer.uint32(74).fork()).ldelim();
    }
    if (message.graphStatus !== undefined) {
      GraphStatus.encode(message.graphStatus, writer.uint32(82).fork()).ldelim();
    }
    if (message.adjustedTargetWaypointInNav !== undefined) {
      Waypoint.encode(message.adjustedTargetWaypointInNav, writer.uint32(98).fork()).ldelim();
    }
    if (message.targetInGps !== undefined) {
      Waypoint.encode(message.targetInGps, writer.uint32(170).fork()).ldelim();
    }
    if (message.landOnComplete === true) {
      writer.uint32(112).bool(message.landOnComplete);
    }
    if (message.criticalRtxType !== 0) {
      writer.uint32(120).int32(message.criticalRtxType);
    }
    for (const v of message.intermediateWaypoints) {
      Waypoint.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    if (message.intermediateWaypointsDoneComputing === true) {
      writer.uint32(208).bool(message.intermediateWaypointsDoneComputing);
    }
    if (message.timeRemaining !== 0) {
      writer.uint32(149).float(message.timeRemaining);
    }
    if (message.isFlyingToWaypoint === true) {
      writer.uint32(152).bool(message.isFlyingToWaypoint);
    }
    if (message.doneFlyingToWaypoint === true) {
      writer.uint32(160).bool(message.doneFlyingToWaypoint);
    }
    if (message.readyForWaypointCommands === true) {
      writer.uint32(176).bool(message.readyForWaypointCommands);
    }
    if (message.isReturning === true) {
      writer.uint32(200).bool(message.isReturning);
    }
    if (message.taskUuid !== undefined) {
      Uuid.encode(message.taskUuid, writer.uint32(218).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GoToWaypointStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGoToWaypointStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.nonce = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.acceptedTargetNonce = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.distanceTraveled = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.totalDistance = reader.float();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.target = TargetWaypoint.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.triggeringFault = reader.int32() as any;
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.failingFault = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.motionArgs = MotionArgs.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.traversalStatus = TraversalStatus.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.graphStatus = GraphStatus.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.adjustedTargetWaypointInNav = Waypoint.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.targetInGps = Waypoint.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.landOnComplete = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.criticalRtxType = reader.int32() as any;
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.intermediateWaypoints.push(Waypoint.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.intermediateWaypointsDoneComputing = reader.bool();
          continue;
        case 18:
          if (tag !== 149) {
            break;
          }

          message.timeRemaining = reader.float();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.isFlyingToWaypoint = reader.bool();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.doneFlyingToWaypoint = reader.bool();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.readyForWaypointCommands = reader.bool();
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.isReturning = reader.bool();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.taskUuid = Uuid.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GoToWaypointStatus {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      nonce: isSet(object.nonce) ? globalThis.Number(object.nonce) : 0,
      acceptedTargetNonce: isSet(object.acceptedTargetNonce) ? globalThis.Number(object.acceptedTargetNonce) : 0,
      distanceTraveled: isSet(object.distanceTraveled) ? globalThis.Number(object.distanceTraveled) : 0,
      totalDistance: isSet(object.totalDistance) ? globalThis.Number(object.totalDistance) : 0,
      target: isSet(object.target) ? TargetWaypoint.fromJSON(object.target) : undefined,
      state: isSet(object.state) ? goToWaypointState_EnumFromJSON(object.state) : 0,
      source: isSet(object.source) ? goToWaypointSource_EnumFromJSON(object.source) : 0,
      triggeringFault: isSet(object.triggeringFault) ? vehicleFault_EnumFromJSON(object.triggeringFault) : 0,
      failingFault: isSet(object.failingFault) ? vehicleFault_EnumFromJSON(object.failingFault) : 0,
      motionArgs: isSet(object.motionArgs) ? MotionArgs.fromJSON(object.motionArgs) : undefined,
      traversalStatus: isSet(object.traversalStatus) ? TraversalStatus.fromJSON(object.traversalStatus) : undefined,
      graphStatus: isSet(object.graphStatus) ? GraphStatus.fromJSON(object.graphStatus) : undefined,
      adjustedTargetWaypointInNav: isSet(object.adjustedTargetWaypointInNav)
        ? Waypoint.fromJSON(object.adjustedTargetWaypointInNav)
        : undefined,
      targetInGps: isSet(object.targetInGps) ? Waypoint.fromJSON(object.targetInGps) : undefined,
      landOnComplete: isSet(object.landOnComplete) ? globalThis.Boolean(object.landOnComplete) : false,
      criticalRtxType: isSet(object.criticalRtxType) ? criticalRTXType_EnumFromJSON(object.criticalRtxType) : 0,
      intermediateWaypoints: globalThis.Array.isArray(object?.intermediateWaypoints)
        ? object.intermediateWaypoints.map((e: any) => Waypoint.fromJSON(e))
        : [],
      intermediateWaypointsDoneComputing: isSet(object.intermediateWaypointsDoneComputing)
        ? globalThis.Boolean(object.intermediateWaypointsDoneComputing)
        : false,
      timeRemaining: isSet(object.timeRemaining) ? globalThis.Number(object.timeRemaining) : 0,
      isFlyingToWaypoint: isSet(object.isFlyingToWaypoint) ? globalThis.Boolean(object.isFlyingToWaypoint) : false,
      doneFlyingToWaypoint: isSet(object.doneFlyingToWaypoint)
        ? globalThis.Boolean(object.doneFlyingToWaypoint)
        : false,
      readyForWaypointCommands: isSet(object.readyForWaypointCommands)
        ? globalThis.Boolean(object.readyForWaypointCommands)
        : false,
      isReturning: isSet(object.isReturning) ? globalThis.Boolean(object.isReturning) : false,
      taskUuid: isSet(object.taskUuid) ? Uuid.fromJSON(object.taskUuid) : undefined,
    };
  },

  toJSON(message: GoToWaypointStatus): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.nonce !== 0) {
      obj.nonce = Math.round(message.nonce);
    }
    if (message.acceptedTargetNonce !== 0) {
      obj.acceptedTargetNonce = Math.round(message.acceptedTargetNonce);
    }
    if (message.distanceTraveled !== 0) {
      obj.distanceTraveled = message.distanceTraveled;
    }
    if (message.totalDistance !== 0) {
      obj.totalDistance = message.totalDistance;
    }
    if (message.target !== undefined) {
      obj.target = TargetWaypoint.toJSON(message.target);
    }
    if (message.state !== 0) {
      obj.state = goToWaypointState_EnumToJSON(message.state);
    }
    if (message.source !== 0) {
      obj.source = goToWaypointSource_EnumToJSON(message.source);
    }
    if (message.triggeringFault !== 0) {
      obj.triggeringFault = vehicleFault_EnumToJSON(message.triggeringFault);
    }
    if (message.failingFault !== 0) {
      obj.failingFault = vehicleFault_EnumToJSON(message.failingFault);
    }
    if (message.motionArgs !== undefined) {
      obj.motionArgs = MotionArgs.toJSON(message.motionArgs);
    }
    if (message.traversalStatus !== undefined) {
      obj.traversalStatus = TraversalStatus.toJSON(message.traversalStatus);
    }
    if (message.graphStatus !== undefined) {
      obj.graphStatus = GraphStatus.toJSON(message.graphStatus);
    }
    if (message.adjustedTargetWaypointInNav !== undefined) {
      obj.adjustedTargetWaypointInNav = Waypoint.toJSON(message.adjustedTargetWaypointInNav);
    }
    if (message.targetInGps !== undefined) {
      obj.targetInGps = Waypoint.toJSON(message.targetInGps);
    }
    if (message.landOnComplete === true) {
      obj.landOnComplete = message.landOnComplete;
    }
    if (message.criticalRtxType !== 0) {
      obj.criticalRtxType = criticalRTXType_EnumToJSON(message.criticalRtxType);
    }
    if (message.intermediateWaypoints?.length) {
      obj.intermediateWaypoints = message.intermediateWaypoints.map((e) => Waypoint.toJSON(e));
    }
    if (message.intermediateWaypointsDoneComputing === true) {
      obj.intermediateWaypointsDoneComputing = message.intermediateWaypointsDoneComputing;
    }
    if (message.timeRemaining !== 0) {
      obj.timeRemaining = message.timeRemaining;
    }
    if (message.isFlyingToWaypoint === true) {
      obj.isFlyingToWaypoint = message.isFlyingToWaypoint;
    }
    if (message.doneFlyingToWaypoint === true) {
      obj.doneFlyingToWaypoint = message.doneFlyingToWaypoint;
    }
    if (message.readyForWaypointCommands === true) {
      obj.readyForWaypointCommands = message.readyForWaypointCommands;
    }
    if (message.isReturning === true) {
      obj.isReturning = message.isReturning;
    }
    if (message.taskUuid !== undefined) {
      obj.taskUuid = Uuid.toJSON(message.taskUuid);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GoToWaypointStatus>, I>>(base?: I): GoToWaypointStatus {
    return GoToWaypointStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GoToWaypointStatus>, I>>(object: I): GoToWaypointStatus {
    const message = createBaseGoToWaypointStatus();
    message.utime = object.utime ?? 0;
    message.nonce = object.nonce ?? 0;
    message.acceptedTargetNonce = object.acceptedTargetNonce ?? 0;
    message.distanceTraveled = object.distanceTraveled ?? 0;
    message.totalDistance = object.totalDistance ?? 0;
    message.target = (object.target !== undefined && object.target !== null)
      ? TargetWaypoint.fromPartial(object.target)
      : undefined;
    message.state = object.state ?? 0;
    message.source = object.source ?? 0;
    message.triggeringFault = object.triggeringFault ?? 0;
    message.failingFault = object.failingFault ?? 0;
    message.motionArgs = (object.motionArgs !== undefined && object.motionArgs !== null)
      ? MotionArgs.fromPartial(object.motionArgs)
      : undefined;
    message.traversalStatus = (object.traversalStatus !== undefined && object.traversalStatus !== null)
      ? TraversalStatus.fromPartial(object.traversalStatus)
      : undefined;
    message.graphStatus = (object.graphStatus !== undefined && object.graphStatus !== null)
      ? GraphStatus.fromPartial(object.graphStatus)
      : undefined;
    message.adjustedTargetWaypointInNav =
      (object.adjustedTargetWaypointInNav !== undefined && object.adjustedTargetWaypointInNav !== null)
        ? Waypoint.fromPartial(object.adjustedTargetWaypointInNav)
        : undefined;
    message.targetInGps = (object.targetInGps !== undefined && object.targetInGps !== null)
      ? Waypoint.fromPartial(object.targetInGps)
      : undefined;
    message.landOnComplete = object.landOnComplete ?? false;
    message.criticalRtxType = object.criticalRtxType ?? 0;
    message.intermediateWaypoints = object.intermediateWaypoints?.map((e) => Waypoint.fromPartial(e)) || [];
    message.intermediateWaypointsDoneComputing = object.intermediateWaypointsDoneComputing ?? false;
    message.timeRemaining = object.timeRemaining ?? 0;
    message.isFlyingToWaypoint = object.isFlyingToWaypoint ?? false;
    message.doneFlyingToWaypoint = object.doneFlyingToWaypoint ?? false;
    message.readyForWaypointCommands = object.readyForWaypointCommands ?? false;
    message.isReturning = object.isReturning ?? false;
    message.taskUuid = (object.taskUuid !== undefined && object.taskUuid !== null)
      ? Uuid.fromPartial(object.taskUuid)
      : undefined;
    return message;
  },
};

function createBaseIntermediateGoals(): IntermediateGoals {
  return { upGoal: undefined, traverseGoal: undefined, downGoal: undefined };
}

export const IntermediateGoals = {
  encode(message: IntermediateGoals, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.upGoal !== undefined) {
      Waypoint.encode(message.upGoal, writer.uint32(10).fork()).ldelim();
    }
    if (message.traverseGoal !== undefined) {
      Waypoint.encode(message.traverseGoal, writer.uint32(18).fork()).ldelim();
    }
    if (message.downGoal !== undefined) {
      Waypoint.encode(message.downGoal, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IntermediateGoals {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIntermediateGoals();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.upGoal = Waypoint.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.traverseGoal = Waypoint.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.downGoal = Waypoint.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IntermediateGoals {
    return {
      upGoal: isSet(object.upGoal) ? Waypoint.fromJSON(object.upGoal) : undefined,
      traverseGoal: isSet(object.traverseGoal) ? Waypoint.fromJSON(object.traverseGoal) : undefined,
      downGoal: isSet(object.downGoal) ? Waypoint.fromJSON(object.downGoal) : undefined,
    };
  },

  toJSON(message: IntermediateGoals): unknown {
    const obj: any = {};
    if (message.upGoal !== undefined) {
      obj.upGoal = Waypoint.toJSON(message.upGoal);
    }
    if (message.traverseGoal !== undefined) {
      obj.traverseGoal = Waypoint.toJSON(message.traverseGoal);
    }
    if (message.downGoal !== undefined) {
      obj.downGoal = Waypoint.toJSON(message.downGoal);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IntermediateGoals>, I>>(base?: I): IntermediateGoals {
    return IntermediateGoals.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IntermediateGoals>, I>>(object: I): IntermediateGoals {
    const message = createBaseIntermediateGoals();
    message.upGoal = (object.upGoal !== undefined && object.upGoal !== null)
      ? Waypoint.fromPartial(object.upGoal)
      : undefined;
    message.traverseGoal = (object.traverseGoal !== undefined && object.traverseGoal !== null)
      ? Waypoint.fromPartial(object.traverseGoal)
      : undefined;
    message.downGoal = (object.downGoal !== undefined && object.downGoal !== null)
      ? Waypoint.fromPartial(object.downGoal)
      : undefined;
    return message;
  },
};

function createBaseTraversalStatusInternal(): TraversalStatusInternal {
  return { intermediateGoals: undefined };
}

export const TraversalStatusInternal = {
  encode(message: TraversalStatusInternal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.intermediateGoals !== undefined) {
      IntermediateGoals.encode(message.intermediateGoals, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TraversalStatusInternal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTraversalStatusInternal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.intermediateGoals = IntermediateGoals.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TraversalStatusInternal {
    return {
      intermediateGoals: isSet(object.intermediateGoals)
        ? IntermediateGoals.fromJSON(object.intermediateGoals)
        : undefined,
    };
  },

  toJSON(message: TraversalStatusInternal): unknown {
    const obj: any = {};
    if (message.intermediateGoals !== undefined) {
      obj.intermediateGoals = IntermediateGoals.toJSON(message.intermediateGoals);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TraversalStatusInternal>, I>>(base?: I): TraversalStatusInternal {
    return TraversalStatusInternal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TraversalStatusInternal>, I>>(object: I): TraversalStatusInternal {
    const message = createBaseTraversalStatusInternal();
    message.intermediateGoals = (object.intermediateGoals !== undefined && object.intermediateGoals !== null)
      ? IntermediateGoals.fromPartial(object.intermediateGoals)
      : undefined;
    return message;
  },
};

function createBaseGraphStatusInternal(): GraphStatusInternal {
  return {};
}

export const GraphStatusInternal = {
  encode(_: GraphStatusInternal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GraphStatusInternal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGraphStatusInternal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GraphStatusInternal {
    return {};
  },

  toJSON(_: GraphStatusInternal): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GraphStatusInternal>, I>>(base?: I): GraphStatusInternal {
    return GraphStatusInternal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GraphStatusInternal>, I>>(_: I): GraphStatusInternal {
    const message = createBaseGraphStatusInternal();
    return message;
  },
};

function createBaseWaypointHeadingsInternal(): WaypointHeadingsInternal {
  return { startHeadingInNav: 0, targetHeadingInNav: 0, currentHeadingInNav: 0, relativeHeading: 0 };
}

export const WaypointHeadingsInternal = {
  encode(message: WaypointHeadingsInternal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startHeadingInNav !== 0) {
      writer.uint32(13).float(message.startHeadingInNav);
    }
    if (message.targetHeadingInNav !== 0) {
      writer.uint32(21).float(message.targetHeadingInNav);
    }
    if (message.currentHeadingInNav !== 0) {
      writer.uint32(29).float(message.currentHeadingInNav);
    }
    if (message.relativeHeading !== 0) {
      writer.uint32(37).float(message.relativeHeading);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WaypointHeadingsInternal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWaypointHeadingsInternal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.startHeadingInNav = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.targetHeadingInNav = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.currentHeadingInNav = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.relativeHeading = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WaypointHeadingsInternal {
    return {
      startHeadingInNav: isSet(object.startHeadingInNav) ? globalThis.Number(object.startHeadingInNav) : 0,
      targetHeadingInNav: isSet(object.targetHeadingInNav) ? globalThis.Number(object.targetHeadingInNav) : 0,
      currentHeadingInNav: isSet(object.currentHeadingInNav) ? globalThis.Number(object.currentHeadingInNav) : 0,
      relativeHeading: isSet(object.relativeHeading) ? globalThis.Number(object.relativeHeading) : 0,
    };
  },

  toJSON(message: WaypointHeadingsInternal): unknown {
    const obj: any = {};
    if (message.startHeadingInNav !== 0) {
      obj.startHeadingInNav = message.startHeadingInNav;
    }
    if (message.targetHeadingInNav !== 0) {
      obj.targetHeadingInNav = message.targetHeadingInNav;
    }
    if (message.currentHeadingInNav !== 0) {
      obj.currentHeadingInNav = message.currentHeadingInNav;
    }
    if (message.relativeHeading !== 0) {
      obj.relativeHeading = message.relativeHeading;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WaypointHeadingsInternal>, I>>(base?: I): WaypointHeadingsInternal {
    return WaypointHeadingsInternal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WaypointHeadingsInternal>, I>>(object: I): WaypointHeadingsInternal {
    const message = createBaseWaypointHeadingsInternal();
    message.startHeadingInNav = object.startHeadingInNav ?? 0;
    message.targetHeadingInNav = object.targetHeadingInNav ?? 0;
    message.currentHeadingInNav = object.currentHeadingInNav ?? 0;
    message.relativeHeading = object.relativeHeading ?? 0;
    return message;
  },
};

function createBaseGoToWaypointStatusInternal(): GoToWaypointStatusInternal {
  return {
    utime: 0,
    startInNav: undefined,
    targetInNav: undefined,
    waypointHeadingsInternal: undefined,
    traversalStatusInternal: undefined,
    graphStatusInternal: undefined,
  };
}

export const GoToWaypointStatusInternal = {
  encode(message: GoToWaypointStatusInternal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    if (message.startInNav !== undefined) {
      TransPb.encode(message.startInNav, writer.uint32(18).fork()).ldelim();
    }
    if (message.targetInNav !== undefined) {
      TransPb.encode(message.targetInNav, writer.uint32(26).fork()).ldelim();
    }
    if (message.waypointHeadingsInternal !== undefined) {
      WaypointHeadingsInternal.encode(message.waypointHeadingsInternal, writer.uint32(34).fork()).ldelim();
    }
    if (message.traversalStatusInternal !== undefined) {
      TraversalStatusInternal.encode(message.traversalStatusInternal, writer.uint32(42).fork()).ldelim();
    }
    if (message.graphStatusInternal !== undefined) {
      GraphStatusInternal.encode(message.graphStatusInternal, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GoToWaypointStatusInternal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGoToWaypointStatusInternal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startInNav = TransPb.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.targetInNav = TransPb.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.waypointHeadingsInternal = WaypointHeadingsInternal.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.traversalStatusInternal = TraversalStatusInternal.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.graphStatusInternal = GraphStatusInternal.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GoToWaypointStatusInternal {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      startInNav: isSet(object.startInNav) ? TransPb.fromJSON(object.startInNav) : undefined,
      targetInNav: isSet(object.targetInNav) ? TransPb.fromJSON(object.targetInNav) : undefined,
      waypointHeadingsInternal: isSet(object.waypointHeadingsInternal)
        ? WaypointHeadingsInternal.fromJSON(object.waypointHeadingsInternal)
        : undefined,
      traversalStatusInternal: isSet(object.traversalStatusInternal)
        ? TraversalStatusInternal.fromJSON(object.traversalStatusInternal)
        : undefined,
      graphStatusInternal: isSet(object.graphStatusInternal)
        ? GraphStatusInternal.fromJSON(object.graphStatusInternal)
        : undefined,
    };
  },

  toJSON(message: GoToWaypointStatusInternal): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.startInNav !== undefined) {
      obj.startInNav = TransPb.toJSON(message.startInNav);
    }
    if (message.targetInNav !== undefined) {
      obj.targetInNav = TransPb.toJSON(message.targetInNav);
    }
    if (message.waypointHeadingsInternal !== undefined) {
      obj.waypointHeadingsInternal = WaypointHeadingsInternal.toJSON(message.waypointHeadingsInternal);
    }
    if (message.traversalStatusInternal !== undefined) {
      obj.traversalStatusInternal = TraversalStatusInternal.toJSON(message.traversalStatusInternal);
    }
    if (message.graphStatusInternal !== undefined) {
      obj.graphStatusInternal = GraphStatusInternal.toJSON(message.graphStatusInternal);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GoToWaypointStatusInternal>, I>>(base?: I): GoToWaypointStatusInternal {
    return GoToWaypointStatusInternal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GoToWaypointStatusInternal>, I>>(object: I): GoToWaypointStatusInternal {
    const message = createBaseGoToWaypointStatusInternal();
    message.utime = object.utime ?? 0;
    message.startInNav = (object.startInNav !== undefined && object.startInNav !== null)
      ? TransPb.fromPartial(object.startInNav)
      : undefined;
    message.targetInNav = (object.targetInNav !== undefined && object.targetInNav !== null)
      ? TransPb.fromPartial(object.targetInNav)
      : undefined;
    message.waypointHeadingsInternal =
      (object.waypointHeadingsInternal !== undefined && object.waypointHeadingsInternal !== null)
        ? WaypointHeadingsInternal.fromPartial(object.waypointHeadingsInternal)
        : undefined;
    message.traversalStatusInternal =
      (object.traversalStatusInternal !== undefined && object.traversalStatusInternal !== null)
        ? TraversalStatusInternal.fromPartial(object.traversalStatusInternal)
        : undefined;
    message.graphStatusInternal = (object.graphStatusInternal !== undefined && object.graphStatusInternal !== null)
      ? GraphStatusInternal.fromPartial(object.graphStatusInternal)
      : undefined;
    return message;
  },
};

function createBaseSkillsRTXSettings(): SkillsRTXSettings {
  return { utime: 0, ignoreLostPhoneConnection: false, lowBatteryRtxBehavior: 0 };
}

export const SkillsRTXSettings = {
  encode(message: SkillsRTXSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).int64(message.utime);
    }
    if (message.ignoreLostPhoneConnection === true) {
      writer.uint32(24).bool(message.ignoreLostPhoneConnection);
    }
    if (message.lowBatteryRtxBehavior !== 0) {
      writer.uint32(32).int32(message.lowBatteryRtxBehavior);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SkillsRTXSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSkillsRTXSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.ignoreLostPhoneConnection = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lowBatteryRtxBehavior = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SkillsRTXSettings {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      ignoreLostPhoneConnection: isSet(object.ignoreLostPhoneConnection)
        ? globalThis.Boolean(object.ignoreLostPhoneConnection)
        : false,
      lowBatteryRtxBehavior: isSet(object.lowBatteryRtxBehavior)
        ? skillsRTXSettings_LowBatteryRtxBehaviorFromJSON(object.lowBatteryRtxBehavior)
        : 0,
    };
  },

  toJSON(message: SkillsRTXSettings): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.ignoreLostPhoneConnection === true) {
      obj.ignoreLostPhoneConnection = message.ignoreLostPhoneConnection;
    }
    if (message.lowBatteryRtxBehavior !== 0) {
      obj.lowBatteryRtxBehavior = skillsRTXSettings_LowBatteryRtxBehaviorToJSON(message.lowBatteryRtxBehavior);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SkillsRTXSettings>, I>>(base?: I): SkillsRTXSettings {
    return SkillsRTXSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SkillsRTXSettings>, I>>(object: I): SkillsRTXSettings {
    const message = createBaseSkillsRTXSettings();
    message.utime = object.utime ?? 0;
    message.ignoreLostPhoneConnection = object.ignoreLostPhoneConnection ?? false;
    message.lowBatteryRtxBehavior = object.lowBatteryRtxBehavior ?? 0;
    return message;
  },
};

function createBaseCriticalRTXType(): CriticalRTXType {
  return {};
}

export const CriticalRTXType = {
  encode(_: CriticalRTXType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CriticalRTXType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCriticalRTXType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CriticalRTXType {
    return {};
  },

  toJSON(_: CriticalRTXType): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CriticalRTXType>, I>>(base?: I): CriticalRTXType {
    return CriticalRTXType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CriticalRTXType>, I>>(_: I): CriticalRTXType {
    const message = createBaseCriticalRTXType();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
